import React from 'react';
import * as URI from 'urijs';
import AccountingNavs from '@/components/accountings/accounting_navs';
import AccountingPurchaseOrders from '@/components/accountings/accounting_purchase_orders';
import AccountingTransactions from '@/components/accountings/accounting_transactions';
import AccountingInvoices from '@/components/accountings/accounting_invoices';
import InvoiceUploadModal from '@/components/purchase_order/modals/invoice_upload_modal';
import TransactionModal from '@/components/purchase_order/modals/transaction_modal';
import CashFlow from '@/components/accountings/CashFlow.jsx';

class Accounting extends React.Component {
  constructor(props) {
    super(props);

    this.accountingTransactions = React.createRef();

    sessionStorage.setItem('closeModalUrl', window.location.href);

    this.state = {
      mounted: this.resolveActivePage(),
      openTransactionModal: false,
      openInvoiceUploadModal: false,
      invoiceId: null,
      purchaseOrdersCount: null,
      transactionsCount: null,
      invoicesCount: null,
    };

    window.addEventListener('popstate', (e) => {
      e.preventDefault();
      this.setState({ mounted: this.resolveActivePage() });
    });
  }

  mountPurchaseOrders = () => {
    window.history.pushState({}, 'Accounting', '/purchase_orders/invoices?view=purchaseOrders');
    sessionStorage.setItem('closeModalUrl', window.location.href);
    this.setState({
      mounted: 'purchaseOrders',
    });
  }

  mountInvoices = () => {
    window.history.pushState({}, 'Accounting', '/purchase_orders/invoices?page=1&view[]=invoices&deleted[]=0');
    sessionStorage.setItem('closeModalUrl', window.location.href);
    this.setState({
      mounted: 'invoices',
    });
  }

  mountTransactions = () => {
    window.history.pushState({}, 'Accounting', '/purchase_orders/invoices?page=1&view[]=transactions&state=open');
    sessionStorage.setItem('closeModalUrl', window.location.href);
    this.setState({
      mounted: 'transactions',
    });
  }

  mountCashFlow = () => {
    window.history.pushState(
      {},
      'Accounting',
      '/purchase_orders/invoices?page=1&view[]=cash_flow&state=open',
    );
    this.setState({
      mounted: 'cash_flow',
    });
  }

  resolveActivePage = () => {
    const uri = URI(window.location.href).query(true);
    return (uri.view || uri['view[]']) || 'purchaseOrders';
  };

  onToggleTransactionModal = (invoiceId = null, updateAll = false) => {
    const { openTransactionModal } = this.state;
    this.setState({ openTransactionModal: !openTransactionModal, invoiceId });
    if (updateAll) this.transactionsRefilter();
  }

  onToggleInvoiceUploadModal = () => {
    const { openInvoiceUploadModal } = this.state;
    this.setState({ openInvoiceUploadModal: !openInvoiceUploadModal });
  }

  transactionsRefilter = () => {
    this.accountingTransactions.current.filter();
  }

  updateTotalCounts = (transactionsCount, purchaseOrdersCount, invoicesCount) => {
    this.setState({ transactionsCount, purchaseOrdersCount, invoicesCount });
  }

  updateTotalInvoicesCounts = (invoicesCount, purchaseOrdersCount, transactionsCount) => {
    this.setState({ invoicesCount, purchaseOrdersCount, transactionsCount });
  }

  renderTabs = () => {
    const {
      props: {
        currentUser, companyCurrency, integrationType, standAlone, xero,
      },
      state: { mounted },
    } = this;

    const tabs = {
      purchaseOrders: (
        <AccountingPurchaseOrders
          companyCurrency={companyCurrency}
          updateTotalCounts={this.updateTotalCounts}
          updateTotalInvoicesCounts={this.updateTotalInvoicesCounts}
          standAlone={standAlone}
        />
      ),
      invoices: (
        <AccountingInvoices
          currentUser={currentUser}
          companyCurrency={companyCurrency}
          onToggleTransactionModal={this.onToggleTransactionModal}
          updateTotalInvoicesCounts={this.updateTotalInvoicesCounts}
          integrationType={integrationType}
          updateTotalCounts={this.updateTotalCounts}
          standAlone={standAlone}
          xero={xero}
        />
      ),
      transactions: (
        <AccountingTransactions
          currentUser={currentUser}
          companyCurrency={companyCurrency}
          integrationType={integrationType}
          onToggleTransactionModal={this.onToggleTransactionModal}
          ref={this.accountingTransactions}
          updateTotalCounts={this.updateTotalCounts}
        />
      ),
      cash_flow: <CashFlow />,
    };

    return tabs[mounted];
  }

  render() {
    const {
      state: {
        mounted,
        openTransactionModal,
        invoiceId,
        openInvoiceUploadModal,
        purchaseOrdersCount,
        transactionsCount,
        invoicesCount,
      },
      props: {
        companyCurrency,
        integrationWay,
        companySlug,
        invoiceModuleEnabled,
        cashFlowEnabled,
      },
    } = this;

    const email = process.env.NODE_ENV === 'production'
      ? `${companySlug}@invoice.costtracker.com`
      : `ct-stage+${companySlug}@int.railwaymen.org`;

    return (
      <div className="cell-16">
        <div className="tile with-table tabs with-bulk">
          <AccountingNavs
            mountPurchaseOrders={this.mountPurchaseOrders}
            mountInvoices={this.mountInvoices}
            mountTransactions={this.mountTransactions}
            mountCashFlow={this.mountCashFlow}
            mounted={mounted}
            integrationWay={integrationWay}
            onToggleInvoiceUploadModal={this.onToggleInvoiceUploadModal}
            onToggleTransactionModal={this.onToggleTransactionModal}
            email={email}
            purchaseOrdersCount={purchaseOrdersCount}
            transactionsCount={transactionsCount}
            invoicesCount={invoicesCount}
            invoiceModuleEnabled={invoiceModuleEnabled}
            cashFlowEnabled={cashFlowEnabled}
          />
          {openTransactionModal
            ? (
              <TransactionModal
                invoiceId={invoiceId}
                onToggleTransactionModal={this.onToggleTransactionModal}
                companyCurrency={companyCurrency}
              />
            ) : null}
          {openInvoiceUploadModal
            ? (
              <InvoiceUploadModal
                openInvoiceUploadModal={openInvoiceUploadModal}
                onToggleInvoiceUploadModal={this.onToggleInvoiceUploadModal}
              />
            )
            : null
          }
          {this.renderTabs()}
        </div>
      </div>
    );
  }
}

export default Accounting;
