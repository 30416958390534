import React from 'react';
import DatePicker from 'react-datepicker';

class DeliveryRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowExpanded: !props.delivery.id,
    };
  }

  expandRow = () => {
    this.setState(prevState => ({ rowExpanded: !prevState.rowExpanded }));
  }

  onDateChange = (date) => {
    const { delivery, updateDeliveries } = this.props;
    delivery.deliveryDate = date;

    updateDeliveries(delivery);
  }

  render() {
    const {
      state: {
        rowExpanded,
      },
      props: {
        delivery, editableDelivery,
      },
    } = this;

    return (
      <tbody className={`tbody-with-sub ${rowExpanded ? 'expanded' : ''}`}>
        <tr className="fluid-not-mobile">
          <td colSpan={5} className="fluid-always-display not-sticky">
            <div className="inline-list">
              <span className="mute label fluid-not-mobile">
                { `Delivery ${delivery.number}` || 'New Delivery' }
              </span>
              <span className="with-avatar inline-list">
                {
                  delivery.userAvatar
                    ? (
                      <a className="avatar approve" href="#">
                        <img src={delivery.userAvatar} />
                      </a>
                    )
                    : null
                }
                <div className="user-info">
                  <p>{`${delivery.userFirstName} ${delivery.userLastName}`}</p>
                  <div className="mute">{delivery.createdAt}</div>
                </div>
              </span>
            </div>
          </td>
          <td className="with-as fluid-always-display" colSpan={3}>
            <div className="as-th">
              {I18n.t('purchase_orders.form.deliveries.delivery_date')}
            </div>
            <div className="as-td">
              <DatePicker
                selected={delivery.deliveryDate}
                onChange={this.onDateChange}
                dateFormat="DD.MM.YYYY"
                locale={I18n.locale}
                disabled={!editableDelivery}
                className="input-s width-geo-9"
              />
            </div>
          </td>
          <td className="with-as fluid-always-display" colSpan={2}>
            <div className="as-th">
              {I18n.t('purchase_orders.form.deliveries.delivery_value')}
            </div>
            <div className="as-td total">
              {I18n.toNumber(Math.round(delivery.totalDeliveryValue() * 100) / 100, { delimiter: ' ', precision: 2 })}
            </div>
          </td>
          <td className="actions fluid-not-mobile">
            {
              delivery.files.length !== 0
                ? (
                  <a className="anchor link">
                    <i className="icon-attach_file" />
                    {delivery.files.length}
                  </a>
                ) : null
            }
          </td>
          <td className="actions with-arrow fluid-always-display">
            <a onClick={this.expandRow} className="arrow" />
          </td>
        </tr>
        <tr className="fluid-only-mobile">
          <td colSpan={11} className="fluid-always-display not-sticky nowrap padding-right-0">
            <div className="display-flex nowrap">
              <div className="display-inline-flex">
                <div className="inline-list">
                  <span className="mute label fluid-not-mobile">
                    { `Delivery ${delivery.number}` || 'New Delivery' }
                  </span>
                  <span className="with-avatar inline-list">
                    {
                      delivery.userAvatar
                        ? (
                          <a className="avatar approve" href="#">
                            <img src={delivery.userAvatar} />
                          </a>
                        )
                        : null
                    }
                    <div className="user-info">
                      <p>{`${delivery.userFirstName} ${delivery.userLastName}`}</p>
                      <div className="mute">{delivery.createdAt}</div>
                    </div>
                  </span>
                </div>
              </div>
              <div className="margin-right-3 margin-left-auto">
                <div className="with-as display-flex">
                  <div className="as-th">
                    {I18n.t('purchase_orders.form.deliveries.date')}
                  </div>
                  <div className="as-td">
                    <DatePicker
                      selected={delivery.deliveryDate}
                      onChange={this.onDateChange}
                      dateFormat="DD.MM.YYYY"
                      locale={I18n.locale}
                      disabled={!editableDelivery}
                      className="input-s width-geo-9 text-right"
                      popperPlacement="left-end"
                    />
                  </div>
                </div>
                <div className="with-as display-flex">
                  <div className="as-th">
                    {I18n.t('purchase_orders.form.deliveries.value')}
                  </div>
                  <div className="as-td total output">
                    {I18n.toNumber(Math.round(delivery.totalDeliveryValue() * 100) / 100, { delimiter: ' ', precision: 2 })}
                  </div>
                </div>
                {
                  delivery.files.length !== 0
                    ? (
                      <a className="anchor link">
                        <i className="icon-attach_file" />
                        {delivery.files.length}
                      </a>
                    ) : null
                }
              </div>
            </div>
          </td>
          <td className="actions with-arrow fluid-always-display not-sticky">
            <a onClick={this.expandRow} className="arrow" />
          </td>
        </tr>
      </tbody>
    );
  }
}

export default DeliveryRow;
