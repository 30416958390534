import React, { useState } from 'react';
import DefaultButtons from './buttons/default_buttons';
import ApproverButtons from './buttons/approver_buttons';
import SendToApprovalModal from './modals/send_to_approval_modal';
import Button from '../common/button';

function Buttons({
  saveSupplierInvoice, saveAndCloseSupplierInvoice, deleteSupplierInvoice, permittedEvents, saveWithEvent,
  supplierInvoice, possibleApprovers, transferSupplierInvoice, currentUser, restoreSupplierInvoice,
}) {
  const [activeModel, setActiveModal] = useState(false);

  const openApproveModal = () => {
    setActiveModal(true);
  };

  const sendToApprove = (approverIds) => {
    saveSupplierInvoice('send_to_approve', approverIds);
    setActiveModal(false);
  };

  const reject = () => {
    saveWithEvent({ event: 'rejecting' });
  };

  const onHold = () => {
    saveWithEvent({ event: 'making_on_hold' });
  };

  const approve = () => {
    saveWithEvent({ event: 'approving' });
  };

  const transfer = () => {
    transferSupplierInvoice();
  };

  const renderApproverButtons = () => {
    if (
      (
        permittedEvents?.includes('approving')
        || permittedEvents?.includes('rejecting')
        || permittedEvents?.includes('making_on_hold')
      ) && !supplierInvoice?.approvedBy.includes(currentUser?.id)
    ) {
      return (
        <ApproverButtons
          reject={reject}
          onHold={onHold}
          approve={approve}
        />
      );
    }
  };

  const isSavePrimaryButton = supplierInvoice.state === 'draft';

  return (
    <div className="window-footer modal-footer form-section-footer cell-12">
      <div className="items items-end">
        {
          supplierInvoice.editable
            ? (
              <DefaultButtons
                deleted={supplierInvoice.deletedAt}
                restoreSupplierInvoice={restoreSupplierInvoice}
                saveSupplierInvoice={saveSupplierInvoice}
                deleteSupplierInvoice={deleteSupplierInvoice}
                saveAndCloseSupplierInvoice={saveAndCloseSupplierInvoice}
                isSavePrimaryButton={isSavePrimaryButton}
              />
            )
            : (
              <Button onClick={saveAndCloseSupplierInvoice}>{I18n.t('commons.actions.close')}</Button>
            )
        }
        {
          permittedEvents?.includes('send_to_approve')
            ? (
              <Button onClick={openApproveModal} primary={!isSavePrimaryButton}>
                {I18n.t('purchase_orders.form.sent_to_approval')}
              </Button>
            ) : (renderApproverButtons())
        }
        {
          permittedEvents?.includes('transfering')
            ? (
              <Button primary onClick={transfer}>{I18n.t('supplier_invoices.edit.transfer')}</Button>
            ) : null
        }
      </div>
      <SendToApprovalModal
        sendToApprove={sendToApprove}
        possibleApprovers={possibleApprovers}
        activeModel={activeModel}
        setActiveModal={setActiveModal}
      />
    </div>
  );
}

export default Buttons;
