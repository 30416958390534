import PropTypes from 'prop-types';

export const dateFieldBasePropTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  disableRanges: PropTypes.bool,
  noInput: PropTypes.bool,
};

export const dateFilterFormat = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
};

export const dateInputFormat = { day: '2-digit', month: '2-digit', year: 'numeric' };

export const dateDisplayFormat = 'dd.MM.yyyy';

export const alternativeDateDisplayFormat = 'dd/MM/yyyy';

export const usDateDisplayFormat = 'MM/dd/yyyy';

export const usAlternativeDateDisplayFormat = 'yyyy-MM-dd';

export const dateDisplayFormats = { standard: dateDisplayFormat, alternative: alternativeDateDisplayFormat };

export const usDateDisplayFormats = { standard: usDateDisplayFormat, alternative: usAlternativeDateDisplayFormat };
