import React from "react";
import { humanizedPrice } from "@/components/shared/number_converter";
import Row from "@/components/ui/table/row";
import CellTd from "@/components/ui/table/cell_td";
import { parseDate } from "@/tools";

function Document({ doc }) {

  const pathToRecord = () => {
    const { urlId, poNumber } = doc;
    if (!urlId.length) return "-";

    return <a href={`/purchase_orders/${urlId}/edit`} target="_blank">{poNumber}</a>
  }

  return (
    <Row>
      <CellTd>{I18n.t(`cash_flow.doc_types.${doc.recordType}`)}</CellTd>
      <CellTd>{doc.supplierName}</CellTd>
      <CellTd>{doc.number}</CellTd>
      <CellTd>{pathToRecord()}</CellTd>
      <CellTd>
        {["transaction", "manual_transaction"].includes(doc.recordType)
          ? parseDate(doc.docDate, "dd.MM.yyyy")
          : "-"}
      </CellTd>
      <CellTd>
        {["transaction", "manual_transaction"].includes(doc.recordType)
          ? "-"
          : parseDate(doc.deliveryDate, "dd.MM.yyyy")}
      </CellTd>
      <CellTd>
        {parseDate(doc.paymentDate, 'dd.MM.yyyy')}
      </CellTd>
      <CellTd className="text-right">{humanizedPrice(doc.value)}</CellTd>
      <CellTd className="text-right">{doc.currency}</CellTd>
      <CellTd className="text-right">{humanizedPrice(doc.valueInCompanyCurrency)}</CellTd>
    </Row>
  );
}

export default Document;
