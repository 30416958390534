import React from 'react';
import CategoryGraph from './category_graph';
import numberFormatter from '../shared/number_formatter';

class BudgetGraph extends React.Component {
  constructor(props) {
    super(props);

    this.notInvoicedRef = React.createRef();
    this.invoicedRef = React.createRef();
    this.plannedRef = React.createRef();
    this.remainingRef = React.createRef();

    const { currentCompany: { ppoEnabled }, budget } = props;

    this.plannedAmount = (ppoEnabled ? budget.planned : 0.0) - this.purchaseOrderSum();

    this.state = {
      showMore: false,
      budgetRemaining: 0,
      levels: {
        invoiced: 0,
        notInvoiced: 0,
        planned: 0,
        remaining: 0,
      },
    };
  }

  componentDidMount() {
    const {
      state: { levels },
      props: { currentCompany: { ppoEnabled }, budget },
    } = this;

    const incoming = this.purchaseOrderSum();

    this.setState({ budgetRemaining: ppoEnabled ? Number(budget.remaining) + Number(incoming) : Number(budget.remaining) }, () => {
      if (this.getDistanceBetweenElements(this.notInvoicedRef.current, this.invoicedRef.current) < 52) {
        this.setState({ levels: { ...levels, notInvoiced: levels.notInvoiced + 1 } });
      }
      if (this.getDistanceBetweenElements(this.plannedRef.current, this.notInvoicedRef.current) < 52) {
        this.setState({ levels: { ...levels, planned: levels.planned + 1 } });
      }
      if (this.getDistanceBetweenElements(this.invoicedRef.current, this.plannedRef.current) < 52 && this.getDistanceBetweenElements(this.notInvoicedRef.current, this.invoicedRef.current) < 52) {
        this.setState({ levels: { ...levels, invoiced: levels.invoiced + 1, planned: levels.planned + 2 } });
      }
      if (this.getDistanceBetweenElements(this.remainingRef.current, this.plannedRef.current) < 52) {
        this.setState({ levels: { ...levels, remaining: levels.remaining + 1 } });
      }
    });
  }


  onToggleShowMore = () => {
    const { showMore } = this.state;

    this.setState({ showMore: !showMore });
  }

  purchaseOrderSum = () => {
    const { items, exchangeRate } = this.props;

    return numberFormatter(items.reduce((prev, item) => prev + (item.quantity * item.price), 0) * exchangeRate);
  }

  percentage = (value, amount, reduce = 1) => {
    if (value > 0) return (((Math.round((value * 100 / amount) * 100) / 100) || 0) / reduce).toFixed(5);
    return 0;
  }

  calcReduce = (arrayOfNumbers) => {
    let max = 0;
    for (const number of arrayOfNumbers) {
      const currentMax = parseInt(number).toString().length;
      if (currentMax > max) {
        max = currentMax;
      }
    }
    return ((10 ** max) / 100);
  }

  categoryItems = (category_id) => {
    const { items } = this.props;

    return items.filter(item => (item.category_id == '' ? null : item.category_id) == category_id);
  }

  getPositionAtCenter = (element) => {
    if (element === null) return 0;

    const {
      top, left, width, height,
    } = element.getBoundingClientRect();
    return {
      x: left + width / 2,
      y: top + height / 2,
    };
  }

  getDistanceBetweenElements = (a, b) => {
    const aPosition = this.getPositionAtCenter(a);
    const bPosition = this.getPositionAtCenter(b);

    return (aPosition.x - bPosition.x);
  }

  levelClass = (fieldName) => {
    const { levels } = this.state;

    if (levels[fieldName] === 0) return '';
    if (levels[fieldName] === 1) return 'up';
    if (levels[fieldName] > 1) return `up-${levels[fieldName] - 1}`;
  }

  render = () => {
    const { budget, exchangeRate, currentCompany: { ppoEnabled } } = this.props;
    const { showMore, budgetRemaining } = this.state;
    const incoming = this.purchaseOrderSum();
    const reduce = this.calcReduce(
      [
        this.percentage(budget.invoiced, budget.amount),
        this.percentage(budget.notInvoiced, budget.amount),
        this.percentage(this.plannedAmount, budget.amount),
        this.percentage(incoming, budget.amount),
        this.percentage(budgetRemaining - incoming, budget.amount),
      ],
    );

    return (
      <div className="form-section cell-12">
        <div className="form-section-content">
          <div className={`inter-section grid ${showMore ? 'more' : ''}`}>
            <div className="inter-section-title cell-auto">
              <span className="inter-title">
                <i className="icon-info" />
                <span>Budget</span>
                <strong>{budget.displayName}</strong>
              </span>
              { budget.strictControl && !showMore && (
                <div className={`inter-label ${budget.categoryStrictControl ? 'warning' : ''}`}>
                  <i className="icon-lock" />
                  {budget.categoryStrictControl ? 'category strict' : 'strict'}
                </div>
              )}
            </div>
            <div className={`inter-section-content cell-fill with-up ${this.levelClass('planned')} ${this.levelClass('invoiced')} ${this.levelClass('notInvoiced')} ${this.levelClass('remaining')}`}>
              <table className="grid charts-grid">
                <tbody>
                  <tr className={`row with-chart with-min-max with-labels ${showMore ? 'with-margin' : ''}`}>
                    <td className="chart-min">0</td>
                    <td className="cell chart-inside chart-medium">
                      <div className={`charts ${budgetRemaining - incoming < 0 ? 'warning' : null}`}>
                        <div className="chart has-label invoiced" style={{ width: `${this.percentage(budget.invoiced, budget.amount, reduce)}%` }}>
                          <span />
                          <div ref={this.invoicedRef} className={`chart-label ${this.levelClass('invoiced')}`}>
                            <div>
                              <div className="chart-label-name">{I18n.t('purchase_orders.form.budget_graph.invoiced')}</div>
                              <div className="chart-label-value">{I18n.toNumber(budget.invoiced, { delimiter: ' ', precision: 0 })}</div>
                            </div>
                            <span />
                          </div>
                        </div>
                        <div className="chart has-label not-invoiced" style={{ width: `${this.percentage(budget.notInvoiced, budget.amount, reduce)}%` }}>
                          <span />
                          <div ref={this.notInvoicedRef} className={`chart-label ${this.levelClass('notInvoiced')}`}>
                            <div>
                              <div className="chart-label-name">{I18n.t('purchase_orders.form.budget_graph.open_commitments')}</div>
                              <div className="chart-label-value">{I18n.toNumber(budget.notInvoiced, { delimiter: ' ', precision: 0 })}</div>
                            </div>
                            <span />
                          </div>
                        </div>
                        { ppoEnabled
                          ? (
                            <div className="chart has-label planned" style={{ width: `${this.percentage(this.plannedAmount, budget.amount, reduce)}%` }}>
                              <span />
                              <div ref={this.plannedRef} className={`chart-label ${this.levelClass('planned')}`}>
                                <div>
                                  <div className="chart-label-name">{I18n.t('purchase_orders.form.budget_graph.planned')}</div>
                                  <div className="chart-label-value">{I18n.toNumber(this.plannedAmount, { delimiter: ' ', precision: 0 })}</div>
                                </div>
                                <span />
                              </div>
                            </div>
                          ) : null
                        }
                        <div className="chart has-label current" style={{ width: `${this.percentage(incoming, budget.amount, reduce)}%` }}>
                          <span />
                          <div className="chart-label">
                            <div>
                              <div className="chart-label-value">
                                {I18n.t('purchase_orders.form.budget_graph.this_purchase')}
                                <div className="chart-label-value">{I18n.toNumber(incoming, { delimiter: ' ', precision: 0 })}</div>
                              </div>
                            </div>
                            <span />
                          </div>
                        </div>
                        <div className="chart has-label remaining" style={{ width: `${this.percentage(budgetRemaining - incoming, budget.amount, reduce)}%` }}>
                          <span />
                          <div ref={this.remainingRef} className={`chart-label ${this.levelClass('remaining')}`}>
                            <div>
                              <div className="chart-label-name">{I18n.t('purchase_orders.form.budget_graph.remaining')}</div>
                              <div className="chart-label-value">{I18n.toNumber(budgetRemaining - incoming, { delimiter: ' ', precision: 0 })}</div>
                            </div>
                            <span />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="chart-max">{I18n.toNumber(budget.amount, { delimiter: ' ', precision: 0 })}</td>
                  </tr>
                  { showMore
                    ? (
                      <React.Fragment>
                        {budget.categories.map(
                          category => (
                            <CategoryGraph
                              category={category}
                              items={this.categoryItems(category.id)}
                              percentage={this.percentage}
                              ppoEnabled={ppoEnabled}
                              exchangeRate={exchangeRate}
                            />
                          ),
                        )}
                        <tr className="row with-legend">
                          <td className="cell" colSpan="3">
                            <div className="chart-legend">
                              { ppoEnabled
                                ? (
                                  <div className="legend">
                                    <div className="dots">
                                      <div className="dot chart-planned" />
                                      <div className="dot chart-warning-planned" />
                                    </div>
                                    <label className="label">
                                      {I18n.t('purchase_orders.form.budget_graph.planned')}
                                    </label>
                                  </div>
                                ) : null
                              }
                              <div className="legend">
                                <div className="dots">
                                  <div className="dot chart-invoiced" />
                                  <div className="dot chart-warning-invoiced" />
                                </div>
                                <label className="label">
                                  {I18n.t('purchase_orders.form.budget_graph.invoiced')}
                                </label>
                              </div>
                              <div className="legend">
                                <div className="dots">
                                  <div className="dot chart-not-invoiced" />
                                  <div className="dot chart-warning-not-invoiced" />
                                </div>
                                <label className="label">
                                  {I18n.t('purchase_orders.form.budget_graph.open_commitments')}
                                </label>
                              </div>
                              <div className="legend">
                                <div className="dots">
                                  <div className="dot chart-remaining" />
                                </div>
                                <label className="label">
                                  {I18n.t('purchase_orders.form.budget_graph.remaining')}
                                </label>
                              </div>
                              <div className="legend">
                                <div className="dots">
                                  <div className="dot chart-current" />
                                </div>
                                <label className="label">
                                  {I18n.t('purchase_orders.form.budget_graph.this_purchase')}
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ) : null
                  }
                </tbody>
              </table>
            </div>
            <div className="inter-section-actions cell-auto">
              <a className="link" onClick={this.onToggleShowMore}>
                { showMore ? 'Show less' : 'Show details' }
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BudgetGraph;
