import uuid from 'uuid/v4';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import BigDecimal from 'js-big-decimal';
import PasteItemsLink from '@/components/paste_items_link';
import DescriptionLineModel from '@/models/description_line_model';
import Modal from '@/components/shared/modal';
import arrayMove from '@/components/shared/array_move';
import NumberConverter from '../shared/number_converter';
import ReceiveModal from './items/receive_modal';
import SortableList from './items/sortable_list';
import DeliveryModal from './modals/delivery_modal';
import SupplierItemsModal from './modals/supplier_items_modal';
import ItemError from './items/item_error';
import Textarea from '../shared/textarea';

class OrderItems extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    categories: PropTypes.array,
    editable: PropTypes.bool,
    currentUser: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.addItem = this.addItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.handleUpdateItem = this.handleUpdateItem.bind(this);
    this.onToggleChange = this.onToggleChange.bind(this);
    this.yieldReceived = this.yieldReceived.bind(this);
    this.totalRateOverall = this.totalRateOverall.bind(this);
    this.resetMark = this.resetMark.bind(this);
    this.openModal = this.openModal.bind(this);
    this.roundCalculate = this.roundCalculate.bind(this);
    this.addAndFocusItem = this.addAndFocusItem.bind(this);

    const {
      props: {
        items,
        purchaseOrder: { calculation_method, description_lines, tax },
      },
    } = this;

    this.deliveryModal = React.createRef();
    this.showDelivery = this.showDelivery();
    this.editableDelivery = this.editableDelivery();

    this.state = {
      items,
      descriptionLines: description_lines.map(line => new DescriptionLineModel(line)),
      totalPrice: 0,
      totalQuantity: 0,
      totalOverall: 0,
      totalRateOverall: 0,
      additionals: 0,
      markAsReceived: false,
      isDeliveryModalOpen: false,
      deliveries: [],
      isNewDelivery: false,
      calculationMethod: calculation_method,
      tax: tax || '',
      taxValue: 0,
    };
  }

  componentDidMount() {
    this.setTotal();
  }

  onToggleChange(e) {
    const { state } = this;
    this.setState({
      [e.target.name]: !state[e.target.name],
    });
  }

  onItemSortByNumber = () => {
    const { state: { items, descriptionLines }, updatePosition, filterElements } = this;
    const sortedItems = items.sort((a, b) => a.sku - b.sku);
    let newElemetns = [...sortedItems, ...descriptionLines];
    newElemetns = newElemetns.map((item, index) => {
      const newItem = item;
      newItem.position = index;
      return newItem;
    });

    const [newItems, newLines] = filterElements(newElemetns);
    updatePosition(newItems, newLines);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { state: { items, descriptionLines }, updatePosition, filterElements } = this;
    const elements = [...items, ...descriptionLines].sort((a, b) => a.position - b.position);
    let newElemetns = arrayMove(elements, oldIndex, newIndex);

    newElemetns = newElemetns.map((item, index) => {
      const newItem = item;
      newItem.position = index;
      return newItem;
    });

    const [newItems, newLines] = filterElements(newElemetns);
    updatePosition(newItems, newLines);
  };

  onDescriptionLineChange = (descriptionLine) => {
    const { state: { descriptionLines } } = this;

    const newDescriptionLines = descriptionLines.map((line) => {
      if (line.uuid === descriptionLine.uuid) {
        return descriptionLine;
      }
      return line;
    });

    this.setState({ descriptionLines: newDescriptionLines });
  }

  onAddDescriptionLine = () => {
    const { state: { descriptionLines, items }, props: { saveDraft } } = this;
    const newPosition = [...descriptionLines, ...items].length;
    const newDescriptionLine = new DescriptionLineModel({ position: newPosition });

    this.setState({
      descriptionLines: [...descriptionLines, newDescriptionLine],
    }, () => {
      saveDraft();
      this.setTotal();
    });
  }

  onTaxChange = (e) => {
    let { target: { value } } = e;
    const { target } = e;
    const { state: { totalOverall } } = this;
    if (value === '' || value === undefined || value === null) {
      value = '';
    } else {
      value = Number(value).toFixed(2);
      value = NumberConverter(value);
    }

    const position = target.selectionStart;
    const taxPercentage = BigDecimal.divide(value, 100);
    const taxValue = Number(BigDecimal.multiply(totalOverall, taxPercentage)).toFixed(2);
    const totalTaxValue = Number(BigDecimal.add(taxValue, totalOverall)).toFixed(2);

    this.setState({
      [e.target.name]: value,
      taxValue,
      totalTaxValue,
    }, () => {
      target.selectionEnd = position;
    });
  }

  onDeleteDescriptionLine = (descriptionLine) => {
    const { state: { descriptionLines }, props: { saveDraft } } = this;

    const newDescriptionLines = descriptionLines.map((line) => {
      if (line.uuid === descriptionLine.uuid) {
        const newLine = line;
        newLine._destroy = 1;
        return newLine;
      }
      return line;
    });

    this.setState({ descriptionLines: newDescriptionLines }, () => {
      saveDraft();
      this.setTotal();
    });
  }

  setTotal() {
    const {
      props: {
        setTotalItemsValue, setTotalItemsCurrencyValue, exchangeRate, setParitallyReceive,
      },
    } = this;
    const total = this.calculate();
    const roundTotal = this.roundCalculate();

    this.setState({
      totalPrice: total.price,
      totalQuantity: total.quantity,
      totalOverall: total.total,
      taxValue: total.taxValue,
      totalTaxValue: total.totalTaxValue,
    });

    setTotalItemsCurrencyValue(total.total);
    setTotalItemsValue(roundTotal.total);
    const currencyTotal = Number(BigDecimal.multiply(total.total, exchangeRate)).toFixed(2);
    const deviation = Number(BigDecimal.subtract(currencyTotal, roundTotal.total)).toFixed(2);

    if (deviation !== 0) {
      this.setState({ additionals: deviation });
    } else {
      this.setState({ additionals: 0 });
    }

    this.setState({ totalRateOverall: currencyTotal }, () => {
      const reactEvent = document.createEvent('HTMLEvents');
      reactEvent.initEvent('react-ready', true, true);
      document.dispatchEvent(reactEvent);
      if (total.total <= this.totalDeliveredValue()) {
        setParitallyReceive(false);
      } else {
        setParitallyReceive(true);
      }
    });
  }

  updatePosition = (items, descriptionLines) => {
    const { saveDraft } = this.props;
    this.setState({ items, descriptionLines }, () => saveDraft());
  }

  closeDeliveryModal = () => {
    this.setState({ isDeliveryModalOpen: false, isNewDelivery: false });
  }

  editableDelivery = () => {
    const { currentUser: { roles }, purchaseOrder: { state } } = this.props;
    const receiver = (roles.includes('receive_goods') && (state !== 'completed' && state !== 'received'));
    return roles.includes('administrator') || receiver;
  }

  filterElements = (elements) => {
    const newItems = elements.filter(element => element.e_type === 'item');
    const newLines = elements.filter(element => element.eType === 'line');

    return [newItems, newLines];
  }

  openDeliveryModal = () => {
    this.setState({ isDeliveryModalOpen: true, isNewDelivery: false });
  }

  openNewDeliveryModal = () => {
    this.setState({ isDeliveryModalOpen: true, isNewDelivery: true });
  }

  updateOrderItems = (items) => {
    this.setState({ items });
  }

  totalDeliveredValue = () => {
    if (this.deliveryModal.current) {
      const { deliveries } = this.deliveryModal.current.state;
      if (deliveries.length === 0) {
        return 0;
      }
      return deliveries.map(delivery => delivery.totalDeliveryValue()).reduce((acc, delivery) => Number(acc) + Number(delivery));
    }

    return 0;
  }

  showDelivery = () => {
    const {
      props: {
        editable, currentUser, purchaseOrder,
      },
      userIs,
    } = this;

    let dispActions = true;
    dispActions = !editable;
    if ((userIs('approve') || userIs('purchase')) && (!userIs('receive_goods')) && !currentUser.isAdmin) dispActions = false;
    if ((userIs('approve') || userIs('purchase')) && (userIs('receive_goods')) && !currentUser.isAdmin && (purchaseOrder.state === 'draft' || purchaseOrder.state === 'sent_to_approve' || purchaseOrder.state === 'approved')) dispActions = false;
    if ((userIs('archive') || userIs('accounting')) && !currentUser.isAdmin && (purchaseOrder.state === 'draft' || purchaseOrder.state === 'sent_to_approve' || purchaseOrder.state === 'approved')) dispActions = false;
    if ((userIs('archive') || userIs('accounting')) && !currentUser.isAdmin && (purchaseOrder.state !== 'draft' && purchaseOrder.state !== 'sent_to_approve' && purchaseOrder.state !== 'approved')) dispActions = true;
    if (currentUser.isAdmin && (purchaseOrder.state === 'draft' || purchaseOrder.state === 'rejected' || purchaseOrder.state === 'sent_to_approve' || purchaseOrder.state === 'approved')) dispActions = false;
    if (currentUser.isAdmin && (purchaseOrder.state !== 'draft' && purchaseOrder.state !== 'rejected' && purchaseOrder.state !== 'sent_to_approve' && purchaseOrder.state !== 'approved')) dispActions = true;
    return dispActions;
  }

  userIs = (role) => {
    const { currentUser: { roles } } = this.props;
    return roles.includes(role);
  }

  addItemsToPoItemList = (supplierItems) => {
    const { state: { items, descriptionLines }, props: { saveDraft } } = this;
    const mappedItems = supplierItems.map((supplierItem, index) => (
      {
        name: supplierItem.description,
        price: (supplierItem.grossPrice * (100 - supplierItem.discount)) / 100,
        quantity: supplierItem.minimumOrderQuantity,
        category_id: '',
        _destroy: 0,
        mounted: false,
        uuid: uuid(),
        unit_measure_quantity: '',
        unit_measure_price: '',
        position: [...items, ...descriptionLines].length + index,
        sku: supplierItem.number,
        unit: supplierItem.unitMeasure,
        supplier_item_id: supplierItem.id,
        e_type: 'item',
        currency: supplierItem.currency,
        supplier_id: supplierItem.supplierId,
      }
    ));

    this.setState({
      items: [...items, ...mappedItems],
    }, () => {
      const { props: { onToggleSupplierItemModal } } = this;
      saveDraft();
      this.setTotal();

      const reactEvent = document.createEvent('HTMLEvents');
      reactEvent.initEvent('react-ready', true, true);
      document.dispatchEvent(reactEvent);
      onToggleSupplierItemModal();
    });
  }

  addPasteItemsToPoItemList = (pasteItemsRows) => {
    const { state: { items, descriptionLines }, props: { saveDraft } } = this;
    const mappedItems = pasteItemsRows.map((pasteItemsRow, index) => (
      {
        name: pasteItemsRow.description,
        price: pasteItemsRow.unit_price,
        quantity: pasteItemsRow.quantity,
        category_id: '',
        _destroy: 0,
        mounted: false,
        uuid: uuid(),
        unit_measure_quantity: '',
        unit_measure_price: '',
        position: [...items, ...descriptionLines].length + index,
        sku: pasteItemsRow.sku,
        unit: pasteItemsRow.unit,
        e_type: 'item',
      }
    ));

    return new Promise((resolve) => {
      this.setState({
        items: [...items, ...mappedItems],
      }, () => {
        saveDraft();
        this.setTotal();

        const reactEvent = document.createEvent('HTMLEvents');
        reactEvent.initEvent('react-ready', true, true);
        document.dispatchEvent(reactEvent);
        resolve();
      });
    });
  }

  showUncategorized = () => {
    const { props: { budget } } = this;

    if (budget?.uncategorizedAmount <= 0 && budget?.amount != 0) return false;

    return true;
  }

  onChangeCalculationMethod = (e) => {
    const { value } = e.target;
    const { props: { saveDraft } } = this;

    this.setState({
      calculationMethod: value,
    }, () => { this.setTotal(); saveDraft(); });
  }

  escapeEdit = async () => {
    const { saveDraft } = this.props;
    saveDraft();
  }

  totalRateOverall() {
    const { totalRateOverall } = this.state;
    return totalRateOverall;
  }

  updateItems(items, budgetChanged) {
    const { state } = this;
    let newItems = items;
    if (budgetChanged) {
      if (items == null) {
        newItems = state.items.map((i) => {
          const newItem = i;
          newItem.category_id = null;
          return newItem;
        });
      } else {
        newItems = items.map((i) => {
          const newItem = i;
          newItem.category_id = null;
          return newItem;
        });
      }
    }

    this.setState({ items: newItems }, () => { this.setTotal(); });
  }

  updateAndSaveItems(items) {
    const { saveDraft } = this.props;
    this.setState({ items }, () => { this.setTotal(); saveDraft(); });
  }

  calculate() {
    let price = 0;
    let quantity = 0;
    let total = 0;
    const { items, tax } = this.state;
    items.map((item) => {
      if (_.isEmpty(item._destroy)) {
        price += item.price * 1;
        quantity += item.quantity * 1;
        total = Number(BigDecimal.add(total, Number(BigDecimal.multiply(item.price, item.quantity))));
      }
    });

    total = Number(total.toFixed(2));

    const taxPercentage = BigDecimal.divide(tax, 100);
    let taxValue = Number(BigDecimal.multiply(total, taxPercentage));
    let totalTaxValue = Number(BigDecimal.add(taxValue + total));

    taxValue = taxValue.toFixed(2);
    totalTaxValue = totalTaxValue.toFixed(2);

    return {
      price, quantity, total, taxValue, totalTaxValue,
    };
  }

  roundCalculate() {
    let price = 0;
    let quantity = 0;
    let total = 0;
    const { props: { exchangeRate }, state: { items } } = this;

    items.map((item) => {
      if (_.isEmpty(item._destroy)) {
        price += item.price;
        quantity += item.quantity * 1;
        total += Math.round(item.price * item.quantity * exchangeRate * 100) / 100;
      }
    });

    return { price, quantity, total };
  }

  addItem(props) {
    const { state: { items, descriptionLines }, props: { saveDraft } } = this;
    const { focus } = props;
    const newItem = {
      name: '',
      price: '',
      quantity: '',
      category_id: items[items.length - 1]?.category_id,
      _destroy: 0,
      mounted: true,
      uuid: uuid(),
      position: [...items, ...descriptionLines].length,
      unit_measure_quantity: '',
      unit_measure_price: '',
      e_type: 'item',
      focus: focus || false,
    };

    this.setState({ items: [...items, newItem] }, () => {
      saveDraft();
      this.setTotal();
      const reactEvent = document.createEvent('HTMLEvents');
      reactEvent.initEvent('react-ready', true, true);
      document.dispatchEvent(reactEvent);
    });
  }

  addAndFocusItem() {
    this.addItem({ focus: true });
  }

  yieldReceived() {
    const { items } = this.state;
    return items.map((item) => {
      if (item.id) {
        return {
          id: item.id,
          uuid: item.uuid,
          received: item.received,
          name: item.name,
          price: item.price,
          unit_measure_quantity: item.unit_measure_quantity,
          unit_measure_price: item.unit_measure_price,
          quantity: item.quantity,
          category_id: item.category_id,
          _destroy: item._destroy,
          received_quantity: item.received_quantity,
          e_type: 'item',
        };
      }
    });
  }

  handleUpdateItem(itemState, uuidItem, calculate) {
    const { items } = this.state;
    const findItem = items.find(item => item.uuid === uuidItem);
    const newItemState = itemState;
    newItemState.position = findItem.position;
    const newItems = items.map((item) => {
      if (item.uuid === uuidItem) {
        return newItemState;
      }
      return item;
    });

    this.setState({
      items: newItems,
    }, () => { calculate && this.setTotal(); });
  }

  deleteItem(itemState, uuidItem) {
    const { saveDraftWithLoading } = this.props;

    if (!itemState.id) {
      const { items } = this.state;
      const newItems = items.filter(item => item.uuid !== uuidItem);
      this.setState({
        items: newItems,
      }, () => {
        this.setTotal();
      });
    } else {
      const { items } = this.state;
      const newItems = items.map((item) => {
        if (item.uuid === uuidItem) {
          return itemState;
        }
        return item;
      });
      this.setState({ items: newItems }, () => {
        saveDraftWithLoading();
        this.setTotal();
      });
    }
  }

  resetMark() {
    this.setState({
      markAsReceived: false,
    });
  }

  openModal(e) {
    this.receiveModal.openModal();
    this.onToggleChange(e);
  }

  render() {
    const {
      props: {
        currentUser, receiveGoods, editable, companyCurrency,
        exchangeRate, currency, categories, saveDraft, purchaseOrder,
        isInvoiced, isReceived, confirmReceiveGoods, isDraft,
        purchaseOrderSupplierId, renderSupplierItemsModal, taxEnabled,
        onToggleSupplierItemModal, purchaseOrderErrors, currentCompany: { calculationMethodEnabled },
      },
      state: {
        items, additionals, totalRateOverall, markAsReceived, isDeliveryModalOpen,
        isNewDelivery, totalOverall, descriptionLines, calculationMethod, tax, taxValue, totalTaxValue,
      },
    } = this;

    return (
      <div className="form-section cell-12 with-tile">
        <div className={`tile ${this.showDelivery ? 'receiving' : ''}`}>
          <div className="tile-header form-section-header">
            <span>{I18n.t('purchase_orders.form.item_list')}</span>
            {editable ? (
              <div className="actions">
                <div className="items items-divided as-link">
                  {calculationMethodEnabled ? (
                    <div>
                      <div className="has-popup">
                        <input id="value-method" type="checkbox" />
                        <i className="icon-settings link" />
                        <div className="popup padding-0 left-bottom">
                          <div className="popup-header padding-left-2 padding-right-1 padding-top-2 padding-bottom-1 display-flex">
                            <span>{I18n.t('purchase_orders.form.calculation_method.title')}</span>
                            <label htmlFor="value-method"><i className="icon-close" /></label>
                          </div>
                          <div className="popup-content padding-2 padding-right-5">
                            <label className="has-checkbox display-block">
                              <input
                                type="checkbox"
                                name="calculationMethod"
                                value="default"
                                checked={calculationMethod === 'default'}
                                onChange={this.onChangeCalculationMethod}
                              />
                              <div className="checkbox radio" />
                              <span>
                                <strong>{I18n.t('purchase_orders.form.calculation_method.quantity')}</strong>
                                {' '}
                                {I18n.t('purchase_orders.form.calculation_method.unit_price')}
                              </span>
                            </label>
                            <label className="has-checkbox display-block margin-top-2">
                              <input
                                type="checkbox"
                                name="calculationMethod"
                                value="unit_measure"
                                checked={calculationMethod === 'unit_measure'}
                                onChange={this.onChangeCalculationMethod}
                              />
                              <div className="checkbox radio" />
                              <span>
                                <strong>{I18n.t('purchase_orders.form.calculation_method.weight')}</strong>
                                {' '}
                                {I18n.t('purchase_orders.form.calculation_method.unit_measure')}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <PasteItemsLink addItemsToPoItemList={this.addPasteItemsToPoItemList}>
                      <label className="link">
                        {I18n.t('purchase_orders.form.view_paste_items')}
                      </label>
                    </PasteItemsLink>
                  </div>
                  <div>
                    <label className="link" onClick={onToggleSupplierItemModal}>
                      {I18n.t('purchase_orders.form.view_items_list')}
                    </label>
                  </div>
                  <div>
                    <label className="link" htmlFor="side-form">
                      {I18n.t('purchase_orders.form.view_recent_purchases')}
                    </label>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="tile-content">
            <div className="table-fluid inside-window release-table table-goods">
              <table>
                {this.showDelivery ? (
                  <colgroup>
                    <col className="auto" span="8" />
                    <col className="colored" span="4" />
                  </colgroup>
                ) : null}
                <thead>
                  <tr>
                    <th className="no" onClick={this.onItemSortByNumber}>
                      {I18n.t('purchase_orders.form.items.sku')}
                      <a className="sort desc" />
                    </th>
                    <th className="desc">
                      {I18n.t('purchase_orders.form.items.description')}
                    </th>
                    <th className="category">
                      {I18n.t('purchase_orders.form.items.budget_category')}
                    </th>
                    <th className="number">
                      {I18n.t('purchase_orders.form.items.quantity')}
                    </th>
                    <th className="number">
                      {I18n.t('purchase_orders.form.items.unit')}
                    </th>
                    {calculationMethod === 'unit_measure' ? (
                      <>
                        <th className="number">
                          {I18n.t('purchase_orders.form.items.unit_measure_quantity')}
                        </th>
                        <th className="number">
                          {I18n.t('purchase_orders.form.items.unit_measure_price')}
                        </th>
                      </>
                    ) : null}
                    <th className="number price">
                      {calculationMethod === 'unit_measure' ? (
                        I18n.t('purchase_orders.form.items.price_per_unit')
                      ) : I18n.t('purchase_orders.form.items.unit_price')}
                    </th>
                    {calculationMethod === 'unit_measure' ? (
                      <th className="number price">
                        {I18n.t('purchase_orders.form.items.total_unit')}
                      </th>
                    ) : null}
                    <th className="number price">
                      {I18n.t('purchase_orders.form.items.value')}
                    </th>
                    <th className="number price">
                      {I18n.t('purchase_orders.form.items.value')}
                      <span>{`(${companyCurrency})`}</span>
                    </th>
                    {this.showDelivery ? (
                      <React.Fragment>
                        <th className="actions colored first-colored">
                          {I18n.t('purchase_orders.form.items.pass')}
                        </th>
                        <th className="actions colored">
                          {I18n.t('purchase_orders.form.items.fail')}
                        </th>
                        <th className="actions colored">
                          {I18n.t('purchase_orders.form.items.received')}
                        </th>
                        {editable ? <th className="actions colored" /> : null}
                      </React.Fragment>
                    ) : <th />}
                  </tr>
                </thead>
                <SortableList
                  items={items}
                  descriptionLines={descriptionLines}
                  onDescriptionLineChange={this.onDescriptionLineChange}
                  onDeleteDescriptionLine={this.onDeleteDescriptionLine}
                  budgetId={purchaseOrder.budget_id}
                  showUncategorized={this.showUncategorized()}
                  handleDeleteClick={this.deleteItem}
                  handleEdit={this.handleUpdateItem}
                  rate={exchangeRate}
                  currency={currency}
                  companyCurrency={companyCurrency}
                  categories={categories}
                  editable={editable}
                  receiveGoods={receiveGoods}
                  saveDraft={saveDraft}
                  receiveEditable={(!(isInvoiced && isReceived) || currentUser.isAdmin)}
                  addItem={this.addItem}
                  additionals={additionals}
                  isDraft={isDraft}
                  itemsCount={items.length}
                  onSortEnd={this.onSortEnd}
                  useDragHandle
                  showDelivery={this.showDelivery}
                  purchaseOrderSupplierId={purchaseOrderSupplierId}
                  calculationMethod={calculationMethod}
                />
                <tfoot>
                  <tr className="total">
                    {
                      this.showDelivery
                        ? (
                          <td className="visible actions" rowSpan="2" colSpan={calculationMethod === 'unit_measure' ? 7 : 4}>
                            <div className="items items-divided">
                              {editable
                                ? (
                                  <React.Fragment>
                                    <a className="link" onClick={this.addItem}>
                                      {I18n.t('purchase_orders.form.items.add_another_item')}
                                    </a>
                                    <a className="link" onClick={this.onAddDescriptionLine}>
                                      {I18n.t('purchase_orders.form.items.add_text_line')}
                                    </a>
                                  </React.Fragment>
                                ) : null
                              }
                              {this.editableDelivery ? (
                                <a className="link" onClick={this.openNewDeliveryModal}>
                                  {I18n.t('purchase_orders.form.items.add_new_delivery')}
                                </a>
                              ) : null
                              }
                              <a className="link" onClick={this.openDeliveryModal}>
                                {I18n.t('purchase_orders.form.items.view_all_deliveries')}
                              </a>
                            </div>
                          </td>
                        )
                        : editable
                          ? (
                            <td className="visible actions" rowSpan="2" colSpan={calculationMethod === 'unit_measure' ? 7 : 4}>
                              <div className="items items-divided">
                                <a className="link triggerOnFocus" onClick={this.addItem} href="javascript: void(0);" onFocus={this.addAndFocusItem}>
                                  {I18n.t('purchase_orders.form.items.add_another_item')}
                                </a>
                                <a className="link" onClick={this.onAddDescriptionLine} tabIndex="-1">
                                  {I18n.t('purchase_orders.form.items.add_text_line')}
                                </a>
                              </div>
                            </td>
                          ) : <td className="mobile-hide" colSpan={calculationMethod === 'unit_measure' ? 7 : 4} />
                    }
                    <td className="mobile-hide" />

                    <th className="td number">
                      {I18n.t('purchase_orders.form.items.total_value')}
                    </th>
                    <td className={`total number price ${purchaseOrderErrors?.total_amount ? 'has-error' : ''}`}>
                      <span>
                        {I18n.toNumber(totalOverall, { delimiter: ' ', precision: 2 })}
                        {
                          purchaseOrderErrors?.total_amount
                            ? <ItemError value={purchaseOrderErrors?.total_amount} />
                            : ''
                        }
                      </span>
                    </td>
                    <td className="total number price">
                      {I18n.toNumber(totalRateOverall, { delimiter: ' ', precision: 2 })}
                    </td>
                    {
                      this.showDelivery
                        ? (
                          <React.Fragment>
                            <td colSpan="3" className="colored first-colored">
                              <div className="with-as mobile-as flex">
                                <span className="as-th">
                                  {I18n.t('purchase_orders.form.items.delivered_value')}
                                </span>
                                <span className="as-td number price total">
                                  {I18n.toNumber(this.totalDeliveredValue(), { delimiter: ' ', precision: 2 })}
                                </span>
                              </div>
                            </td>
                            {editable ? <td className="colored mobile-hide" /> : null}
                          </React.Fragment>
                        )
                        : <td className="mobile-hide" />
                    }
                  </tr>
                  {
                    taxEnabled
                      ? (
                        <tr className="tax total">
                          {
                            editable
                              ? null : <td className="mobile-hide" colSpan="2" />
                          }
                          <th className="mark noborder">
                            <div className="input input-r input-flex">
                              <label className="label" htmlFor="tax-value">{`${I18n.t('purchase_orders.form.items.tax')}:`}</label>
                              <Textarea
                                id="tax-value"
                                value={tax}
                                displayValue={I18n.toNumber(tax, { delimiter: ' ', precision: 2 })}
                                name="tax"
                                placeholder="0.00"
                                disabled={!editable}
                                onChange={this.onTaxChange}
                                onBlur={this.escapeEdit}
                                className="input-table width-1"
                              />

                              <div className="addon">%</div>
                            </div>
                          </th>
                          <th className="mark noborder number">{`${I18n.t('purchase_orders.form.items.tax_value')}:`}</th>
                          <td className="td number mark noborder">
                            <div className="with-as mobile-as flex">
                              <span className="as-td number price total">
                                {taxValue}
                              </span>
                              <span className="as-th padding-right-0 text-wrap">
                                {`${I18n.t('purchase_orders.form.items.total_tax_value')}:`}
                              </span>
                            </div>
                          </td>
                          <td className="total number price mark noborder">
                            {totalTaxValue}
                          </td>
                          {
                            this.showDelivery
                              ? (
                                <React.Fragment>
                                  <td colSpan="3" className="mobile-hide mark noborder" />
                                  {editable ? <td className="mobile-hide mark noborder" /> : null}
                                </React.Fragment>
                              )
                              : <td className="mobile-hide mark noborder" />
                          }
                        </tr>
                      ) : null
                  }
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        {
          renderSupplierItemsModal
            ? (
              <Modal>
                <SupplierItemsModal
                  purchaseOrderSupplierId={purchaseOrderSupplierId}
                  onToggleSupplierItemModal={onToggleSupplierItemModal}
                  addItemsToPoItemList={this.addItemsToPoItemList}
                  purchaseOrderCurrency={currency}
                />
              </Modal>
            ) : null
        }
        {receiveGoods && !isReceived
          ? (
            <div>
              <ReceiveModal
                confirmReceiveGoods={confirmReceiveGoods}
                resetMark={this.resetMark}
                ref={receiveModal => (this.receiveModal = receiveModal)}
              />
              <div className="window-footer modal-footer form-section-footer column cell-12">
                <div className="items">
                  <label className="has-checkbox hide-on-complete">
                    <input
                      name="markAsReceived"
                      onChange={this.openModal}
                      checked={markAsReceived}
                      type="checkbox"
                    />
                    <span className="checkbox input-l" />
                    <span className="link">
                      {I18n.t('purchase_orders.form.items.mark_as_received', { value: I18n.toNumber(this.totalDeliveredValue(), { delimiter: ' ', precision: 2 }) })}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          )
          : null}
        {!editable || currentUser.roles.includes('administrator')
          ? (
            <Modal>
              <DeliveryModal
                ref={this.deliveryModal}
                purchaseOrder={purchaseOrder}
                companyCurrency={companyCurrency}
                currentUser={currentUser}
                isDeliveryModalOpen={isDeliveryModalOpen}
                closeDeliveryModal={this.closeDeliveryModal}
                isNewDelivery={isNewDelivery}
                updateOrderItems={this.updateOrderItems}
                exchangeRate={exchangeRate}
                editable={editable}
                editableDelivery={this.editableDelivery}
              />
            </Modal>
          )
          : null}
      </div>
    );
  }
}

export default OrderItems;
