import React, { useEffect, useState } from 'react';
import Actions from '@/components/ui/table/actions';
import Filters from '@/components/ui/table/filters';
import LeftPositionActions from '@/components/ui/table/left_position_actions';
import { humanizedIntegerPrice } from '@/components/shared/number_converter';
import Loader from '@/components/loader';
import { observer } from 'mobx-react-lite';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { addFilters, removeFilters } from '@/tools';
import DateFilterInput from '@/components/accountings/shared/date_filter_input';
import CashFlowStore from '@/stores/CashFlowStore';
import CashFlowSettings from '@/components/accountings/components/CashFlow/CashFlowSettings';

const cashFlowStore = new CashFlowStore();

function CashFlowList({ selectBudget }) {
  const {
    collection: budgets, possibleFilters, settings, columnNames, isLoading, total,
  } = cashFlowStore;
  const [filters, setFilters] = useState({});

  useEffect(() => {
    cashFlowStore.fetchCollection(filters);
  }, [JSON.stringify(filters)]);

  function applyFilters(params) {
    setFilters({ ...filters, ...params });
  }

  function onFilter(filter) {
    setFilters({ ...addFilters(filters, filter) });
  }

  function onRemoveFilter(filter) {
    setFilters({ ...removeFilters(filters, filter) });
  }

  function clearDates() {
    const newFilters = filters;
    delete newFilters.dateFrom;
    delete newFilters.dateTo;

    setFilters({ ...newFilters });
  }

  const hasLoading = isLoading ? ' has-loading' : '';

  return (
    <div className={`tab-pages tile-content${hasLoading}`}>
      <div className="page active">
        <Loader />
        <Actions>
          <LeftPositionActions>
            <Filters
              filters={possibleFilters}
              filterActions={Object.keys(possibleFilters)}
              onFilter={onFilter}
              onRemoveFilter={onRemoveFilter}
            />
            {settings.cutOfDate && (
              <DateFilterInput
                minDateFrom={settings.cutOfDate}
                minDateTo={settings.cutOfDate}
                onDateFilter={applyFilters}
                onRemoveFilter={onRemoveFilter}
                clearDate={clearDates}
                dateFrom={filters.dateFrom}
                dateTo={filters.dateTo}
              />
            )}
          </LeftPositionActions>
          <div>
            <CashFlowSettings applyFilters={applyFilters} settings={settings} />
          </div>
        </Actions>
        <ScrollSync>
          <div className="table-dashboard">
            <ScrollSyncPane>
              <div className="table-scrollable with-always-scrollable always-scroll first-sticky tbody-th-sticky tfoot-sticky">
                <table className="table-cashflow">
                  <thead>
                    <tr>
                      <th className="first">{I18n.t('cash_flow.budget_name')}</th>
                      {columnNames.map(name => (
                        <th key={name}>{name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="mark first">{I18n.t('cash_flow.total')}</td>
                      {total.records.map(record => (
                        <td key={record.paymentDate} className="mark">
                          {humanizedIntegerPrice(record.valueCentsInCompanyCurrencySum / 100)}
                        </td>
                      ))}
                    </tr>
                    {budgets.map(budget => (
                      <tr key={budget.id} className="pointer" onClick={() => selectBudget(budget.id)}>
                        <td className="first">{budget.name}</td>
                        {budget.records.map(record => (
                          <td key={record.paymentDate}>
                            {humanizedIntegerPrice(record.valueCentsInCompanyCurrency / 100)}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </ScrollSyncPane>

            <ScrollSyncPane>
              <div className="table-scrollable with-always-scrollable table-scrollbar-hide ct-mb-0">
                <table className="table-cashflow">
                  <thead>
                    <tr>
                      <th>{I18n.t('cash_flow.total_in_scope')}</th>
                      <th>{I18n.t('cash_flow.out_of_scope')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="double-dark-mark un-border">
                        {humanizedIntegerPrice(total.valueCentsInCompanyCurrencySum / 100)}
                      </td>
                      <td className="double-dark-mark">
                        {humanizedIntegerPrice(total.outOfScope.valueCentsInCompanyCurrencySum / 100)}
                      </td>
                    </tr>
                    {budgets.map(budget => (
                      <tr key={budget.id} className="pointer" onClick={() => selectBudget(budget.id)}>
                        <td className="dark-mark un-border">
                          {humanizedIntegerPrice(budget.totalInValueCentsInCompanyCurrencySum / 100)}
                        </td>
                        <td className="dark-mark">
                          {humanizedIntegerPrice(budget.outOfScope.valueCentsInCompanyCurrency / 100)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </ScrollSyncPane>
          </div>
        </ScrollSync>
      </div>
    </div>
  );
}

export default observer(CashFlowList);
