const numberFormatter = (value) => {
  if (value !== '-') {
    const parsedValue = Number(value).toFixed(2);
    const newValue = Number.isNaN(Number(parsedValue))
      ? Number(0).toFixed(2)
      : parsedValue;

    return newValue;
  }
  return value;
};

export default numberFormatter;
