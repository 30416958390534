import React, { forwardRef, useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import DateFieldBase from '@/components/uiElements/inputs/dates';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import {
  dateFieldBasePropTypes, dateInputFormat, dateDisplayFormats, usDateDisplayFormats,
} from '../constants';

function SingleDateField({ onChange, value, ...rest }, ref) {
  const [date, setDate] = useState();
  const [inputValue, setInputValue] = useState(value);
  const dateLocale = DateTime.fromJSDate(value).locale;

  useEffect(() => {
    if (!value) return;
    setInputValue(DateTime.fromJSDate(value).toLocaleString(dateInputFormat));
  }, [value]);

  useEffect(() => {
    if (!inputValue) return;
    const dateFormats = DateFormats();
    if (DateTime.fromFormat(inputValue, dateFormats.standard).isValid) {
      onChange(DateTime.fromFormat(inputValue, dateFormats.standard).toJSDate());
      setDate(DateTime.fromFormat(inputValue, dateFormats.standard));
    } else if (DateTime.fromFormat(inputValue, dateFormats.alternative).isValid) {
      onChange(DateTime.fromFormat(inputValue, dateFormats.alternative).toJSDate());
      setDate(DateTime.fromFormat(inputValue, dateFormats.alternative));
    }
  }, [inputValue]);

  const DateFormats = () => {
    switch (dateLocale) {
      case 'en-US':
        return usDateDisplayFormats;
      default:
        return dateDisplayFormats;
    }
  };

  return (
    <DateFieldBase
      {...rest}
      ref={ref}
      onChange={newDate => setInputValue(newDate)}
      inputValue={inputValue}
      dateComponent={<Calendar date={date?.toJSDate()} onChange={onChange} />}
    />
  );
}

export default forwardRef(SingleDateField);

SingleDateField.propTypes = {
  ...dateFieldBasePropTypes,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
