import React, { useState } from "react";
import CashFlowList from "./components/CashFlow/CashFlowList/index";
import CashFlowBudget from "./components/CashFlow/CashFlowBudget/index";

function CashFlow() {
  const [budgetId, setBudgetId] = useState(null);

  if (budgetId) return <CashFlowBudget budgetId={budgetId} backToList={() => setBudgetId(null)} />;

  return <CashFlowList selectBudget={setBudgetId} />;
}

export default CashFlow;
