import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker-latest";
import ExportButton from "@/components/shared/buttons/export_button";

function CashFlowSettings({ applyFilters, settings }) {
  const [cutOfDate, setCutOfDate] = useState(null);
  const [periodType, setPeriodType] = useState();

  useEffect(() => {
    setCutOfDate(new Date(settings.cutOfDate));
    setPeriodType(settings.periodType);
  }, [settings]);

  const onCutOfDateChange = (date) => {
    setCutOfDate(date);
    applyFilters({ cutOfDate: date.toISOString(), periodType });
  };

  const onPeriodTypeChange = (type) => {
    setPeriodType(type);
    applyFilters({ cutOfDate: cutOfDate.toISOString(), periodType: type });
  };

  const exportUrl = (format) => `${window.location.origin}/api/cash_flow.${format}${window.location.search}`;
  const detailedExportUrl = (format) => `${window.location.origin}/api/cash_flow/detailed_cash_flow.${format}${window.location.search}`;

  return (
    <>
      <div className="items items-divided center">
        <div className="with-date control">
          <label className="label">{I18n.t("cash_flow.cut_of_date")}</label>
          <span className="output">
            <DatePicker
              selected={cutOfDate}
              onChange={onCutOfDateChange}
              dateFormat="dd.MM.yyyy"
              locale={I18n.locale}
              className="input-s"
            />
          </span>
        </div>
        <div className="display-flex">
          <div className="button-group buttons-switch">
            <input id="weekly" name="scope" type="radio" checked={periodType == "weekly"} readOnly />
            <label className="button button-s" htmlFor="weekly" onClick={() => onPeriodTypeChange("weekly")}>
              {I18n.t("cash_flow.weekly")}
            </label>
            <input id="monthly" name="scope" type="radio" checked={periodType == "monthly"} readOnly />
            <label className="button button-s" htmlFor="monthly" onClick={() => onPeriodTypeChange("monthly")}>
              {I18n.t("cash_flow.monthly")}
            </label>
          </div>
        </div>
        <ExportButton exportUrl={exportUrl} />
        <ExportButton exportUrl={detailedExportUrl} buttonName={I18n.t("cash_flow.detailed_export")} />
      </div>
    </>
  );
}

export default CashFlowSettings;
