import React, { useState, useEffect, useRef } from 'react';
import SupplierItemModel from '../../models/supplier_item_model';
import SupplierModel from '../../models/supplier_model';
import HeaderModel from '../../models/header_model';
import TableHead from './table_head';
import SupplierItemRow from './supplier_item_row';
import GlobalProductRow from './global_product_row';
import ItemListService from '../../services/item_list_service';
import BulkActionBar from './bulk_action_bar';
import FilterBar from './filter_bar';
import ImportItemsModal from './import_items_modal';
import Loader from '../loader';
import Modal from '../shared/modal';
import PaginateWithDots from './paginate_with_dots';
import MobileFiltersModal from './mobile_filters_modal';
import {
  urlParamsSetup, initCurrentStateFilter, initCurrentSupplierFilter,
  initCurrentCategoryFilter, initCurrentGroupFilter,
} from './filters_helper';
import SupplierItemFormModal from './supplier_item_form_modal';
import { supplierItemsHeaders, globalProductsHeaders } from './headers';

function ItemsList({ itemsType }) {
  let urlParams = urlParamsSetup();
  const [currentStateFilter, setCurrentStateFilter] = useState(initCurrentStateFilter(urlParams));
  const [currentSupplierFilter, setCurrentSupplierFilter] = useState(initCurrentSupplierFilter(urlParams));
  const [currentCategoryFilter, setCurrentCategoryFilter] = useState(initCurrentCategoryFilter(urlParams));
  const [currentGroupFilter, setCurrentGroupFilter] = useState(initCurrentGroupFilter(urlParams));
  const pageRendered = useRef(false);
  const [state, setState] = useState({
    supplierItems: [],
    showBulkActionBar: false,
    pages: 1,
    currentPage: urlParams.page ? parseInt(urlParams.page) : 1,
    allBulkActionSelected: false,
    selectedItemsCount: 0,
    sortHeader: null,
    statesFilterOptions: [],
    suppliersFilterOptions: [],
    categoriesFilterOptions: [],
    groupsFilterOptions: [],
    isImportModalOpen: false,
    isMobileFilterModalOpen: false,
    isSupplierItemFormModalOpen: false,
    isLoading: false,
    suppliers: [],
    currencies: [],
    selectedSupplierItem: new SupplierItemModel({}),
    isShowOnly: false,
    headers: (
      itemsType == 'supplier_items' ? supplierItemsHeaders : globalProductsHeaders
    ).map((header) => {
      if (urlParams.order.by === header.name) {
        const newHeader = new HeaderModel(header);
        newHeader.sortDirection = urlParams.order.type;
        return newHeader;
      }
      return new HeaderModel(header);
    }),
  });

  useEffect(() => {
    updateSupplierItemsList();
  }, []);

  const updateSupplierItemsList = () => {
    const finalQuery = newUrlQueries();
    setState(prevState => ({ ...prevState, isLoading: true }));
    ItemListService.index(finalQuery)
      .then(({
        supplier_items: supplierItems,
        possible_filters: {
          states, suppliers, categories, groups, pages,
        },
        currencies,
        company_suppliers: companySuppliers,
      }) => {
        setState(prevState => ({
          ...prevState,
          supplierItems: mappedSupplierItems(supplierItems),
          statesFilterOptions: states,
          suppliersFilterOptions: suppliers,
          categoriesFilterOptions: categories,
          groupsFilterOptions: groups,
          pages,
          isLoading: false,
          suppliers: companySuppliers.map(supplier => new SupplierModel(supplier)),
          currencies,
        }));
        pageRendered.current = true;
      });
  };

  useEffect(() => {
    if (pageRendered.current) {
      updateItemsList(true);
    }
  }, [state.currentPage]);

  useEffect(() => {
    if (pageRendered.current) {
      updateItemsList();
    }
  }, [currentStateFilter, currentSupplierFilter, currentGroupFilter, currentCategoryFilter]);

  useEffect(() => {
    if (pageRendered.current) {
      sortBy(state.sortHeader);
    }
  }, [state.headers]);

  const mappedSupplierItems = supplierItems => supplierItems.map((item) => {
    const newItem = item;
    const discount = Number(newItem.discount);
    const grossPrice = Number(newItem.gross_price);
    if (Number.isNaN(discount) || discount === 0 || Number.isNaN(grossPrice) || grossPrice === 0) {
      newItem.netPrice = grossPrice;
    } else {
      newItem.netPrice = ((grossPrice * (100 - discount)) / 100);
    }
    return new SupplierItemModel(newItem);
  });

  const onChangeBulkAction = (supplierItemId) => {
    const { supplierItems } = state;
    const newSupplierItems = supplierItems.map((item) => {
      if (item.id === supplierItemId) {
        return item.changedBulkAction();
      }
      return item;
    });

    const count = newSupplierItems.filter(item => item.bulkActionsSelected).length;
    const anyUnselected = newSupplierItems.some(({ bulkActionsSelected }) => bulkActionsSelected === false);
    const newStateParams = {
      supplierItems: newSupplierItems,
      allBulkActionSelected: !anyUnselected,
      showBulkActionBar: (count !== 0),
      selectedItemsCount: count,
    };

    setState(prevState => ({
      ...prevState,
      ...newStateParams,
    }));
  };

  const onSelectAllBulkAction = () => {
    const { allBulkActionSelected, supplierItems } = state;
    const newSupplierItems = supplierItems.map(item => item.selectAllBulkAction(allBulkActionSelected));

    setState(prevState => ({
      ...prevState,
      allBulkActionSelected: !allBulkActionSelected,
      supplierItems: newSupplierItems,
      showBulkActionBar: !allBulkActionSelected,
      selectedItemsCount: newSupplierItems.length,
    }));
  };

  const onSort = (sortHeader) => {
    const { headers } = state;
    const newHeaders = headers.map((header) => {
      if (header.name === sortHeader.name) {
        return header.setSortDirection();
      }
      return header.clean();
    });

    setState(prevState => ({
      ...prevState,
      headers: newHeaders,
      sortHeader,
    }));
  };

  const onAddNewClick = () => {
    setState(prevState => ({
      ...prevState,
      selectedSupplierItem: new SupplierItemModel({}),
      isSupplierItemFormModalOpen: !prevState.isSupplierItemFormModalOpen,
      isShowOnly: false,
    }));
  };

  const onToggleImportModal = () => {
    const { isImportModalOpen } = state;
    const newOpenState = !isImportModalOpen;
    setState(prevState => ({
      ...prevState,
      isImportModalOpen: newOpenState,
    }));
    if (!newOpenState) updateItemsListAfterImport();
  };

  const onToggleSupplierItemsFormModal = () => {
    setState(prevState => ({
      ...prevState,
      isSupplierItemFormModalOpen: !prevState.isSupplierItemFormModalOpen,
    }));
  };

  const onToggleMobileFiltersModal = () => {
    setState(prevState => ({
      ...prevState,
      isMobileFilterModalOpen: !prevState.isMobileFilterModalOpen,
    }));
  };

  const onSearchQuery = (value) => {
    if (value.length > 0) {
      urlParams.filters.query = [value];
    } else {
      delete urlParams.filters.query;
    }
    updateItemsList();
  };

  const onPageChange = (page) => {
    if (page !== '...') {
      urlParams.page = page;
      setState(prevState => ({
        ...prevState,
        currentPage: page,
      }));
    }
  };

  const setStateFilter = (stateFilter) => {
    if (currentStateFilter.id === stateFilter.id) {
      setCurrentStateFilter({});
    } else {
      setCurrentStateFilter(stateFilter);
    }
  };

  const setCategoryFilter = (category) => {
    if (currentCategoryFilter.id === category.id) {
      setCurrentCategoryFilter({});
    } else {
      setCurrentCategoryFilter(category);
    }
  };

  const setGroupFilter = (group) => {
    if (currentGroupFilter.id === group.id) {
      setCurrentGroupFilter({});
    } else {
      setCurrentGroupFilter(group);
    }
  };

  const setSupplierFilter = (supplier) => {
    if (currentSupplierFilter.id === supplier.id) {
      setCurrentSupplierFilter({});
    } else {
      setCurrentSupplierFilter(supplier);
    }
  };

  const setUrlFilterParams = (updatePageNumber) => {
    const { currentPage } = state;
    delete urlParams.filters.supplier_id;
    delete urlParams.filters.state;
    delete urlParams.filters.supplier_item_category_id;
    delete urlParams.filters.supplier_item_group_id;
    delete urlParams.page;

    if (currentSupplierFilter.id) {
      urlParams.filters.supplier_id = [currentSupplierFilter.id];
    }
    if (currentStateFilter.name) {
      urlParams.filters.state = [currentStateFilter.name];
    }
    if (currentCategoryFilter.id) {
      urlParams.filters.supplier_item_category_id = [currentCategoryFilter.id];
    }
    if (currentGroupFilter.id) {
      urlParams.filters.supplier_item_group_id = [currentGroupFilter.id];
    }
    if (updatePageNumber) {
      urlParams.page = currentPage;
    }
  };

  const updateItemsList = (updatePageNumber = false) => {
    if (updatePageNumber) {
      setState(prevState => ({ ...prevState, isLoading: true }));
    } else {
      setState(prevState => ({ ...prevState, isLoading: true, currentPage: 1 }));
    }
    setUrlFilterParams(updatePageNumber);

    const finalQuery = newUrlQueries();

    window.history.pushState({}, 'SupplierItems', `/supplier_items${finalQuery}`);
    ItemListService.index(finalQuery)
      .then(({ supplier_items: items, possible_filters: { pages } }) => {
        setState(prevState => ({
          ...prevState,
          supplierItems: mappedSupplierItems(items),
          pages,
          isLoading: false,
        }));
      });
  };

  const updateItemsListAfterImport = () => {
    const finalQuery = newUrlQueries();
    setState(prevState => ({ ...prevState, isLoading: true }));
    ItemListService.index(finalQuery)
      .then(({
        supplier_items: supplierItems,
        possible_filters: {
          states, suppliers, categories, groups, pages,
        },
      }) => {
        setState(prevState => ({
          ...prevState,
          supplierItems: mappedSupplierItems(supplierItems),
          statesFilterOptions: states,
          suppliersFilterOptions: suppliers,
          categoriesFilterOptions: categories,
          groupsFilterOptions: groups,
          pages,
          isLoading: false,
        }));
      });
  };

  const newUrlQueries = () => {
    const {
      filters, order, page, view,
    } = urlParams;

    const mappedFilters = [];
    for (const [key, value] of Object.entries(filters)) {
      if (key === 'state' || key === 'query') {
        if (value.filter(v => v).length > 0) {
          mappedFilters.push(`${key}=${value}`);
        }
      } else {
        value.forEach(val => mappedFilters.push(`${key}[]=${val}`));
      }
    }

    const queries = [
      mappedFilters.join('&'),
      order.by ? `sort[by]=${order.by}&sort[type]=${order.type}` : '',
      page ? `page=${page}` : '',
      view ? `view=${view}` : '',
    ];

    const query = queries.filter(q => q).join('&');

    return (query.length > 0) ? `?${query}` : '';
  };

  const destroyAll = () => {
    const { supplierItems } = state;
    setUrlFilterParams();

    const finalQuery = newUrlQueries();

    const itemsIds = supplierItems.filter(item => item.bulkActionsSelected).map(item => item.id);

    ItemListService.destroyAll(itemsIds, finalQuery)
      .then((items) => {
        setState(prevState => ({
          ...prevState,
          supplierItems: mappedSupplierItems(items),
          showBulkActionBar: !prevState.showBulkActionBar,
          allBulkActionSelected: false,
        }));
      });
  };

  const sortBy = (sortByHeader) => {
    const { order } = urlParams;
    order.by = sortByHeader.name;
    order.type = sortByHeader.sortDirection;

    urlParams = { ...urlParams, order };
    updateItemsList();
  };

  const restoreAll = () => {
    const { supplierItems } = state;
    setUrlFilterParams();

    const finalQuery = newUrlQueries();

    const itemsIds = supplierItems.filter(item => item.bulkActionsSelected).map(item => item.id);

    ItemListService.restoreAll(itemsIds, finalQuery)
      .then((items) => {
        setState(prevState => ({
          ...prevState,
          supplierItems: mappedSupplierItems(items),
          showBulkActionBar: !prevState.showBulkActionBar,
          allBulkActionSelected: false,
        }));
      });
  };

  const onEditClick = (supplierItem) => {
    setState(prevState => ({
      ...prevState,
      selectedSupplierItem: supplierItem,
      isSupplierItemFormModalOpen: true,
      isShowOnly: false,
    }));
  };

  const onShowClick = (supplierItem) => {
    setState(prevState => ({
      ...prevState,
      selectedSupplierItem: supplierItem,
      isSupplierItemFormModalOpen: true,
      isShowOnly: true,
    }));
  };

  const onDeleteClick = (supplierId) => {
    ItemListService.delete(supplierId).then(() => {
      updateSupplierItemsList();
    });
  };

  const _renderInputs = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <input id={`${prefix}_column_${i}`} name={`${prefix}_table`} key={`${prefix}input_column_${i}`} defaultChecked={i === 1} type="radio" readOnly />
    ))
  );

  const _renderLabels = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <label htmlFor={`${prefix}_column_${i}`} key={`${prefix}label_column_${i}`} />
    ))
  );

  const _renderDots = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="dot" key={`${prefix}dot_column_${i}`} />
    ))
  );

  const _renderColls = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="col" key={`${prefix}col_column_${i}`} />
    ))
  );

  const HeaderLinks = () => {
    if (itemsType == 'supplier_items') {
      return (
        <div className="actions ct-ml-auto">
          <div className="items items-divided">
            <a onClick={onAddNewClick} className="link">
              {I18n.t('supplier_items.add_new_item')}
            </a>
            <a onClick={onToggleImportModal} className="link">
              {I18n.t('supplier_items.upload_items')}
            </a>
          </div>
        </div>
      );
    }

    return (
      <div className="actions ct-ml-auto">
        <div className="items items-divided">
          <a onClick={onAddNewClick} className="link">
            {I18n.t('supplier_items.add_new_product')}
          </a>
          <a onClick={onToggleImportModal} className="link">
            {I18n.t('supplier_items.upload_products')}
          </a>
        </div>
      </div>
    );
  };

  const {
    supplierItems, showBulkActionBar, selectedItemsCount, allBulkActionSelected,
    headers, statesFilterOptions, suppliersFilterOptions, categoriesFilterOptions,
    groupsFilterOptions, isImportModalOpen, isLoading, pages, currentPage, isMobileFilterModalOpen,
    suppliers, currencies, isSupplierItemFormModalOpen, selectedSupplierItem, isShowOnly,
  } = state;
  const dotsNumber = headers.length;
  const htmlClasses = isLoading ? ' has-loading' : '';
  const ItemRow = itemsType == 'supplier_items' ? SupplierItemRow : GlobalProductRow;

  return (
    <div className="ct-w-full">
      {
        isSupplierItemFormModalOpen && (
          <SupplierItemFormModal
            suppliers={suppliers}
            currencies={currencies}
            onToggleSupplierItemsFormModal={onToggleSupplierItemsFormModal}
            groups={groupsFilterOptions}
            categories={categoriesFilterOptions}
            updateSupplierItemsList={updateSupplierItemsList}
            selectedSupplierItem={selectedSupplierItem}
            isShowOnly={isShowOnly}
            itemsType={itemsType}
          />
        )
      }
      <div className="toast_box" />
      <div className="tile with-table with-bulk" id="supplier_items_listing">
        <div className="has-filter has-popup mobile-filter">
          <input id="mobile-filters" type="checkbox" />
          <div className="popup">
            <div className="popup-content">
              <h3>{I18n.t('supplier_items.filter_by')}</h3>
              <div className="grid space-2 grid-form">
                <div className="row" />
              </div>
            </div>
          </div>
        </div>
        <div className="tile-header">
          <Modal>
            <ImportItemsModal
              onToggleImportModal={onToggleImportModal}
              isImportModalOpen={isImportModalOpen}
              itemsType={itemsType}
            />
          </Modal>
          <HeaderLinks />
        </div>
        <div className="tile-content">
          {
            !showBulkActionBar
              ? (
                <FilterBar
                  statesFilterOptions={statesFilterOptions}
                  currentStateFilter={currentStateFilter}
                  setStateFilter={setStateFilter}
                  suppliersFilterOptions={suppliersFilterOptions}
                  setCategoryFilter={setCategoryFilter}
                  categoriesFilterOptions={categoriesFilterOptions}
                  currentCategoryFilter={currentCategoryFilter}
                  setGroupFilter={setGroupFilter}
                  groupsFilterOptions={groupsFilterOptions}
                  currentGroupFilter={currentGroupFilter}
                  currentSupplierFilter={currentSupplierFilter}
                  setSupplierFilter={setSupplierFilter}
                  onSearchQuery={onSearchQuery}
                  itemsType={itemsType}
                />
              ) : null
          }
          <div className="has-bulk">
            {
              showBulkActionBar
                ? (
                  <BulkActionBar
                    selectedItemsCount={selectedItemsCount}
                    destroyAll={destroyAll}
                    restoreAll={restoreAll}
                    state={currentStateFilter}
                    onSelectAllBulkAction={onSelectAllBulkAction}
                  />
                ) : null
            }
            <div className={`table-fluid table-scrollable table-column-${dotsNumber} ${htmlClasses}`}>
              <Loader />
              {_renderInputs(dotsNumber)}
              <div className="table-header with-filters">
                <div className="mobile-filters">
                  <Modal>
                    <MobileFiltersModal
                      statesFilterOptions={statesFilterOptions}
                      currentStateFilter={currentStateFilter}
                      setStateFilter={setStateFilter}
                      suppliersFilterOptions={suppliersFilterOptions}
                      setCategoryFilter={setCategoryFilter}
                      categoriesFilterOptions={categoriesFilterOptions}
                      currentCategoryFilter={currentCategoryFilter}
                      setGroupFilter={setGroupFilter}
                      groupsFilterOptions={groupsFilterOptions}
                      currentGroupFilter={currentGroupFilter}
                      currentSupplierFilter={currentSupplierFilter}
                      setSupplierFilter={setSupplierFilter}
                      isMobileFilterModalOpen={isMobileFilterModalOpen}
                      onToggleMobileFiltersModal={onToggleMobileFiltersModal}
                      itemsType={itemsType}
                    />
                  </Modal>
                  <label onClick={onToggleMobileFiltersModal}>
                    <i className="icon-filter_list" />
                  </label>
                </div>
                <div className="table-nav">
                  {_renderLabels(dotsNumber)}
                  {_renderDots(dotsNumber)}
                </div>
              </div>
              <div className="table table-sortable">
                <div className="colgroup">
                  {_renderColls(dotsNumber)}
                </div>
                <TableHead
                  headers={headers}
                  onSelectAllBulkAction={onSelectAllBulkAction}
                  allBulkActionSelected={allBulkActionSelected}
                  onSort={onSort}
                />
                <div className="tbody">
                  { supplierItems.map(supplierItem => (
                    <ItemRow
                      key={supplierItem.id}
                      supplierItem={supplierItem}
                      onChangeBulkAction={onChangeBulkAction}
                      itemsType={itemsType}
                      onEditClick={onEditClick}
                      onShowClick={onShowClick}
                      onDeleteClick={onDeleteClick}
                    />
                  )) }
                </div>
              </div>
            </div>
          </div>
          <div className="tile-footer">
            <PaginateWithDots onPageChange={onPageChange} numberPages={pages} selected={currentPage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemsList;
