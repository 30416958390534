import { SortableElement } from 'react-sortable-hoc';
import React from 'react';
import OrderItem from './item';

const SortableItem = SortableElement(({
  item,
  budgetId,
  handleDeleteClick,
  handleEdit,
  rate,
  currency,
  companyCurrency,
  categories,
  editable,
  receiveGoods,
  saveDraft,
  receiveEditable,
  addItem,
  additional,
  isDraft,
  arrayIndex,
  itemsCount,
  showDelivery,
  purchaseOrderSupplierId,
  showUncategorized,
  calculationMethod,
}) => (
  <OrderItem
    index={(`${item.id}${item.uuid}${budgetId}${item.category_id}`)}
    item={item}
    key={(`${item.id}${item.uuid}${budgetId}${item.category_id}`)}
    handleDeleteClick={handleDeleteClick}
    handleEdit={handleEdit}
    arrayIndex={arrayIndex}
    rate={rate}
    currency={currency}
    companyCurrency={companyCurrency}
    categories={categories}
    editable={editable}
    receiveGoods={receiveGoods}
    saveDraft={saveDraft}
    receiveEditable={receiveEditable}
    addItem={addItem}
    additional={additional}
    isDraft={isDraft}
    itemsCount={itemsCount}
    showDelivery={showDelivery}
    purchaseOrderSupplierId={purchaseOrderSupplierId}
    budgetId={budgetId}
    showUncategorized={showUncategorized}
    calculationMethod={calculationMethod}
  />
));

export default SortableItem;
