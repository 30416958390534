import React from 'react';
import { humanizedPrice } from '@/components/shared/number_converter';
import { dateFormatter } from '@/components/shared/date_formatter';
import SupplierInvoiceContext from '@/context/SupplierInvoiceContext';
import CellDiv from '@/components/ui/table/cell_div';
import Row from '@/components/ui/table/row';

function SupplierInvoiceInformation({
  invoicesNumber, invoicesValue,
}) {
  return (
    <SupplierInvoiceContext.Consumer>
      {
        ({
          amount, reference, docDate, transactionsValue = 0,
        }) => (
          <div className="window-top">
            <span>
              <h1>
                {`${I18n.t('purchase_orders.invoices.attach_transactions_to', { number: invoicesNumber })}:`}
              </h1>
            </span>
            <span>
              <div className="table total">
                <Row rowType="div">
                  <CellDiv className="label">
                    {`${I18n.t('purchase_orders.invoices.selected_transactions_value')}:`}
                  </CellDiv>
                  <CellDiv className="value">
                    {humanizedPrice(invoicesValue || transactionsValue)}
                  </CellDiv>
                </Row>
                <Row rowType="div">
                  <CellDiv className="label">
                    {`${I18n.t('purchase_orders.invoices.invoice_amount')}:`}
                  </CellDiv>
                  <CellDiv className="value">
                    {humanizedPrice(amount)}
                  </CellDiv>
                </Row>
              </div>
              <div className="table total">
                <Row rowType="div">
                  <CellDiv className="label">
                    {`${I18n.t('supplier_invoices.edit.side_image_section.reference')}:`}
                  </CellDiv>
                  <CellDiv className="value">
                    {reference}
                  </CellDiv>
                  <CellDiv className="label">
                    {`${I18n.t('supplier_invoices.edit.doc_date')}:`}
                  </CellDiv>
                  <CellDiv className="value">
                    {dateFormatter(docDate)}
                  </CellDiv>
                </Row>
              </div>
            </span>
          </div>
        )
      }
    </SupplierInvoiceContext.Consumer>
  );
}

export default SupplierInvoiceInformation;
