import React from 'react';
import PropTypes from 'prop-types';
import numberFormatter from '@/components/shared/number_formatter';

class Receive extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    received: PropTypes.bool,
  }

  render() {
    const {
      propsItem, item, children, editable,
    } = this.props;

    const toNumber = attr => numberFormatter(attr);

    const received = propsItem.pass && item.quantity ? `${toNumber(propsItem.pass)}/${toNumber(item.quantity)}` : null;

    return (
      <React.Fragment>
        <td data-th={I18n.t('purchase_orders.form.deliveries.pass')} className="colored first-colored pass number">
          <span>
            {toNumber(propsItem.pass)}
          </span>
        </td>
        <td data-th={I18n.t('purchase_orders.form.deliveries.fail')} className="colored fail number">
          <span>
            {toNumber(propsItem.fail)}
          </span>
        </td>
        <td data-th={I18n.t('purchase_orders.form.items.received')} className="colored number">
          <span>
            {received}
          </span>
        </td>
        { editable ? children : null }
      </React.Fragment>
    );
  }
}

export default Receive;
