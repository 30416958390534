import Model from './model';
import SupplierModel from './supplier_model';

class SupplierItemModel extends Model {
  constructor({ supplier, ...attributes }) {
    super(attributes);
    if (supplier) {
      this.supplier = new SupplierModel(supplier);
      this.supplierId = supplier.id;
    }
    this.bulkActionsSelected = false;
  }

  changedBulkAction = () => {
    this.bulkActionsSelected = !this.bulkActionsSelected;
    return this;
  }

  selectAllBulkAction = (value) => {
    this.bulkActionsSelected = !value;
    return this;
  }

  toParams = (itemType) => {
    const params = {
      supplier_id: this.supplierId || null,
      number: this.number || null,
      product_number: this.productNumber || null,
      global_number: this.globalNumber || null,
      supplier_item_group: this.group || null,
      supplier_item_category: this.category || null,
      description: this.description || null,
      unit_measure: this.unitMeasure || null,
      currency: this.currency || null,
      gross_price: this.grossPrice || null,
      discount: this.discount || null,
      minimum_order_quantity: this.minimumOrderQuantity || null,
      item_type: itemType || null,
    };
    if (this.id) params.id = this.id;

    return params;
  }
}

export default SupplierItemModel;
