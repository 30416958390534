const NumberConverter = (value) => {
  if (value === '.' || value === '' || value === ',') return 0;
  const [convertedValue] = value.replace(',', '.').match(/((^([0-9-]{0,1})+([.,]{0,1})[0-9]{0,2}))/);

  return convertedValue.replace(/((^0+))(?=\d+)/, '');
};

export const humanizedPrice = (value) => {
  const roundedInvoicesValue = (Math.round(value * 100) / 100).toFixed(2);
  const parsedInvoicesValue = roundedInvoicesValue.toString();
  return parsedInvoicesValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ').replace('.', ',');
};

export const humanizedIntegerPrice = (value) => {
  const roundedInvoicesValue = (Math.round(value * 100) / 100).toFixed(0);
  const parsedInvoicesValue = roundedInvoicesValue.toString();
  if (Number.isNaN(Number(parsedInvoicesValue))) return 0;
  return parsedInvoicesValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ').replace('.', ',');
};

export default NumberConverter;
