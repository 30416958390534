import React, { useState, useEffect } from 'react';
import SupplierItemModel from '../../models/supplier_item_model';
import { withLimit } from '../shared/utils';
import ItemListService from '../../services/item_list_service';
import SupplierItemForm from './supplier_item_form';
import GlobalProductForm from './global_product_form';

function SupplierItemFormModal({
  suppliers, onToggleSupplierItemsFormModal, currencies, groups,
  categories, updateSupplierItemsList, selectedSupplierItem, isShowOnly,
  itemsType,
}) {
  const [supplierItem, setSupplierItem] = useState(selectedSupplierItem);
  const [supplierSearchValue, setSupplierSearchValue] = useState('');
  const [currencySearchValue, setCurrencySearchValue] = useState('');
  const [selectSuppliers, setSelectSuppliers] = useState(withLimit(suppliers));
  const [selectCurrencies, setSelectCurrencies] = useState(withLimit(currencies));
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  // We are using typingTimeout to not flood our servers with request when user didn't finish typing in search bar
  // 350 ms should be long enough and fast enough at the same time
  let typingTimeout = null;

  useEffect(() => {
    if (supplierSearchValue === '') { return setSelectSuppliers(withLimit(suppliers)); }
    typingTimeout = setTimeout(() => {
      const newSelectSuppliers = suppliers.filter(supplier => supplier.name.toLowerCase().includes(supplierSearchValue.toLowerCase()));
      setSelectSuppliers(withLimit(newSelectSuppliers));
    }, 350);
  }, [supplierSearchValue]);

  useEffect(() => {
    if (currencySearchValue === '') { return setSelectCurrencies(withLimit(currencies)); }
    typingTimeout = setTimeout(() => {
      const newSelectCurrencies = currencies.filter(currency => currency.toLowerCase().includes(currencySearchValue.toLowerCase()));
      setSelectCurrencies(withLimit(newSelectCurrencies));
    }, 350);
  }, [currencySearchValue]);

  const onSupplierSearchChange = ({ target: { value } }) => {
    if (typingTimeout) clearTimeout(typingTimeout);

    setSupplierSearchValue(value);
  };

  const onCurrencySearchChange = ({ target: { value } }) => {
    if (typingTimeout) clearTimeout(typingTimeout);

    setCurrencySearchValue(value);
  };

  const onChange = ({ target: { name, value } }) => {
    supplierItem[name] = value;
    setSupplierItem(new SupplierItemModel({ ...supplierItem }));
  };

  const onSupplierChange = ({ target: { value, dataset: { currency } } }) => {
    supplierItem.supplierId = value;
    if (supplierItem.supplierId !== '') {
      supplierItem.currency = currency;
      supplierItem.supplier = null;
    }
    setSupplierItem(new SupplierItemModel({ ...supplierItem }));
  };

  const calculateNetPrice = (discount, grossPrice) => {
    if (Number.isNaN(discount) || discount === 0 || Number.isNaN(grossPrice) || grossPrice === 0) return `${grossPrice}`;

    return grossPrice * ((100 - discount) / 100.0);
  };

  const onGrossPriceOrDiscountChange = ({ target: { name, value } }) => {
    supplierItem[name] = value;
    const discount = Number(supplierItem.discount);
    const grossPrice = Number(supplierItem.grossPrice);
    supplierItem.netPrice = calculateNetPrice(discount, grossPrice);
    setSupplierItem(new SupplierItemModel({ ...supplierItem }));
  };

  const onSave = () => {
    setIsSaving(true);
    const action = supplierItem.id === undefined ? 'create' : 'update';
    ItemListService[action](supplierItem.toParams(itemsType))
      .then((response) => {
        if (response.success) {
          onToggleSupplierItemsFormModal();
          updateSupplierItemsList();
        } else {
          setErrors(response.errors);
        }
        setIsSaving(false);
      });
  };

  if (itemsType == 'supplier_items') {
    return (
      <SupplierItemForm
        onToggleSupplierItemsFormModal={onToggleSupplierItemsFormModal}
        groups={groups}
        categories={categories}
        isShowOnly={isShowOnly}
        supplierItem={supplierItem}
        onSave={onSave}
        onGrossPriceOrDiscountChange={onGrossPriceOrDiscountChange}
        onSupplierChange={onSupplierChange}
        onChange={onChange}
        onSupplierSearchChange={onSupplierSearchChange}
        selectSuppliers={selectSuppliers}
        isSaving={isSaving}
        errors={errors}
      />
    );
  }

  return (
    <GlobalProductForm
      onToggleSupplierItemsFormModal={onToggleSupplierItemsFormModal}
      groups={groups}
      categories={categories}
      isShowOnly={isShowOnly}
      supplierItem={supplierItem}
      onSave={onSave}
      onGrossPriceOrDiscountChange={onGrossPriceOrDiscountChange}
      onChange={onChange}
      isSaving={isSaving}
      errors={errors}
      selectCurrencies={selectCurrencies}
      onCurrencySearchChange={onCurrencySearchChange}
    />
  );
}

export default SupplierItemFormModal;
