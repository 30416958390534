import { SortableContainer } from 'react-sortable-hoc';
import React from 'react';
import SortableItem from './sortable_item';
import SortableLine from './sortable_line';

const SortableList = SortableContainer(({
  items,
  descriptionLines,
  budgetId,
  handleDeleteClick,
  handleEdit,
  rate,
  currency,
  companyCurrency,
  categories,
  editable,
  receiveGoods,
  saveDraft,
  receiveEditable,
  addItem,
  additionals,
  isDraft,
  itemsCount,
  showDelivery,
  purchaseOrderSupplierId,
  onDescriptionLineChange,
  onDeleteDescriptionLine,
  showUncategorized,
  calculationMethod,
}) => (
  <tbody>
    { [...items, ...descriptionLines].sort((a, b) => a.position - b.position).map((item, i) => (
      item.e_type === 'item' ? (
        <SortableItem
          budgetId={budgetId}
          item={item}
          key={`item-${item.id}${item.uuid}-${calculationMethod}-${currency}`}
          handleDeleteClick={handleDeleteClick}
          handleEdit={handleEdit}
          arrayIndex={item.uuid}
          rate={rate}
          currency={currency}
          companyCurrency={companyCurrency}
          categories={categories}
          editable={editable}
          receiveGoods={receiveGoods}
          saveDraft={saveDraft}
          receiveEditable={receiveEditable}
          addItem={addItem}
          additional={i == items.length - 1 && i != 0 ? additionals : 0}
          isDraft={isDraft}
          itemsCount={itemsCount}
          index={i}
          showDelivery={showDelivery}
          purchaseOrderSupplierId={purchaseOrderSupplierId}
          showUncategorized={showUncategorized}
          calculationMethod={calculationMethod}
        />
      ) : (
        <SortableLine
          onDescriptionLineChange={onDescriptionLineChange}
          onDeleteDescriptionLine={onDeleteDescriptionLine}
          saveDraft={saveDraft}
          editable={editable}
          descriptionLine={item}
          index={i}
          key={`sortable-${item.id}${item.uuid}`}
          calculationMethod={calculationMethod}
          showDelivery={showDelivery}
        />
      )
    )) }
  </tbody>
));

export default SortableList;
