import React from 'react';
import Table from '../shared/table';
import * as swipe from '../shared/swipe';
import ArchiveService from '../../services/archive_service';
import ColumnViewSettings from './common/column_view_settings';
import PurchaseOrderFilters from '../accountings/shared/purchase_order_filters';
import TableHead from '../accountings/shared/table_head';
import PaginateWithDots from '../item_list/paginate_with_dots';
import Loader from '../loader';
import ExportButton from '../shared/buttons/export_button';
import PurchaseOrderItemRow from './purchase_order_items/purchase_order_item_row';

class PurchaseOrderItems extends Table {
  constructor(props) {
    super(props);

    const urlFilters = this.urlParamsSetup();
    const page = urlFilters.page ? parseInt(urlFilters.page) : 1;

    this.filters = urlFilters.filters;
    this.order = urlFilters.order;

    this.state = {
      purchaseOrderItems: [],
      pages: 1,
      currentPage: page,
      columnView: [],
      filters: [],
      filterActions: [],
      showDeleted: this.filters.state ? this.filters.state.includes('deleted') : false,
      filtersComponentKey: 1,
      columnViewAttributes: [],
    };
  }

  componentDidMount() {
    swipe.triggerSwipeEvent();
    this.onTableLoading();
    ArchiveService.purchaseOrderItems(window.location.search).then(({
      purchaseOrderItems, columnView, pages, filters,
    }) => {
      this.setState({
        purchaseOrderItems,
        columnView,
        pages,
        filters,
        filterActions: filters ? Object.keys(filters) : [],
        columnViewAttributes: columnView.purchaseOrderItemColumns(),
      });
      this.onTableLoad();
      window.initTableFluidManual();
    });
  }

  componentDidUpdate() {
    window.resetTableFluidManual();
  }

  onFilter = ({ name, value }) => {
    const stringValue = value.toString();

    if (this.filters[name]) {
      this.filters[name].push(stringValue);
    } else {
      this.filters[name] = [stringValue];
    }

    this.filter();
  }

  filterDeleted = () => {
    const { showDeleted } = this.state;

    if (showDeleted) {
      this.filters.state = [];
    } else {
      this.filters.state = ['deleted'];
    }

    const uniqFilterKey = Math.floor(Math.random() * 1000);

    this.filter({ showDeleted: !showDeleted, filtersComponentKey: uniqFilterKey });
  }

  filter = (additionalState = {}, page = 1) => {
    const attributes = {
      custom: Object.keys(this.filters).map(key => ({ name: key, value: this.filters[key] })),
      page,
      order: this.order,
    };

    this.onTableLoading();

    ArchiveService.purchaseOrderItemsFilter(attributes).then(({
      purchaseOrderItems, pages, query,
    }) => {
      window.history.pushState({}, 'Archive', query);
      this.setState({
        purchaseOrderItems,
        pages,
        currentPage: 1,
        filtersComponentKey: Math.floor(Math.random() * 1000),
        ...additionalState,
      });
      this.onTableLoad();
    });
  }

  updateColumnView = ({ target: { value } }) => {
    const { columnView } = this.state;
    columnView[value] = !columnView[value];
    this.setState({
      columnView,
    }, () => {
      ArchiveService.updateColumnView({
        type: 'purchase_order_item',
        column_view: columnView.toParams('purchase_order_item'),
      });
    });
  }

  headers = () => {
    const { currentCompany } = this.props;
    const { columnView } = this.state;

    return [
      {
        name: 'po_number',
        translation: 'archive.items.po_number',
        visible: columnView.poNumber,
      },
      {
        name: 'raised',
        translation: 'archive.items.raised',
        visible: columnView.raised,
      },
      {
        name: 'supplier_name',
        translation: 'archive.items.supplier',
        visible: columnView.supplierName,
      },
      {
        name: 'budget_name',
        translation: 'archive.items.budget_name',
        visible: columnView.budgetName,
      },
      {
        name: 'category_name',
        translation: 'archive.items.category_name',
        visible: columnView.categoryName,
      },
      {
        name: 'number',
        translation: 'archive.items.number',
        visible: columnView.number,
      },
      {
        name: 'description',
        translation: 'archive.items.description',
        visible: columnView.description,
      },
      {
        name: 'quantity',
        translation: 'archive.items.quantity',
        class: 'number price',
        visible: columnView.quantity,
      },
      {
        name: 'price_cents',
        translation: 'archive.items.price',
        class: 'number price',
        visible: columnView.price,
      },
      {
        name: 'unit',
        translation: 'archive.items.unit',
        class: 'number price',
        visible: columnView.unit,
      },
      {
        name: 'ordered_value_cents',
        translation: 'archive.items.ordered_value',
        class: 'number price',
        visible: columnView.orderedValue,
      },
      {
        name: 'currency',
        translation: 'archive.items.currency',
        class: 'number price',
        visible: columnView.currency,
      },
      {
        name: 'ordered_currency_value_cents',
        translation: 'archive.items.ordered_currency_value',
        attributes: { currency: currentCompany.currency },
        class: 'number price',
        visible: columnView.orderedCurrencyValue,
      },
      {
        name: 'received_quantity',
        translation: 'archive.items.received_quantity',
        class: 'number price',
        visible: columnView.receivedQuantity,
      },
      {
        name: 'received_value_cents',
        translation: 'archive.items.received_value',
        class: 'number price',
        visible: columnView.receivedValue,
      },
      {
        name: 'state',
        translation: 'archive.items.state',
        class: 'has-status',
        visible: columnView.state,
      },
    ].filter(object => object.visible);
  }

  exportUrl = format => `${window.location.origin}/api/archive/purchase_order_items.${format}${window.location.search}`;

  render() {
    const {
      state: {
        purchaseOrderItems, isLoading, filters, filterActions, filtersComponentKey,
        pages, currentPage, columnView, columnViewAttributes, showDeleted,
      },
      props: { currentCompany },
    } = this;
    const dotsNumber = this.headers().length;
    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <React.Fragment>
        <div className="tab-addons">
          <ColumnViewSettings
            columnView={columnView}
            handleUpdate={this.updateColumnView}
            visibleColumns={columnViewAttributes}
          />
          <ExportButton exportUrl={this.exportUrl} />
        </div>
        <PurchaseOrderFilters
          onFilter={this.onFilter}
          onRemoveFilter={this.onRemoveFilter}
          onDateFilter={this.onDateFilter}
          onQueryFilter={this.onQueryFilter}
          currentFilters={this.filters}
          clearDate={this.clearDate}
          filters={filters}
          filterActions={filterActions}
          integrationRefesh={false}
          key={`availableFiltersPurchaseOrders${filtersComponentKey}`}
          exportEnable={false}
        />
        <div>
          <div className={`table-fluid table-scrollable table-column-${dotsNumber}${htmlClasses}`}>
            <Loader />
            {this._renderInputs(dotsNumber)}
            <div className="table-header with-filters">
              <div className="table-nav">
                {this._renderLabels(dotsNumber)}
                {this._renderDots(dotsNumber)}
              </div>
            </div>
            <div className="table">
              <div className="colgroup">
                {this._renderColls(dotsNumber)}
              </div>
              <TableHead
                onOrder={this.onOrder}
                order={this.order}
                headers={this.headers()}
              />
              <div className="tbody">
                {!isLoading && purchaseOrderItems.map(purchaseOrderItem => (
                  <PurchaseOrderItemRow
                    key={purchaseOrderItem.id}
                    purchaseOrderItem={purchaseOrderItem}
                    columnView={columnView}
                    currentCompany={currentCompany}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="tile-footer">
          {showDeleted
            ? <a className="link" onClick={this.filterDeleted}>{I18n.t('archive.hide_deleted_items')}</a>
            : <a className="link" onClick={this.filterDeleted}>{I18n.t('archive.show_deleted_items')}</a>
          }
          <PaginateWithDots onPageChange={this.onPageChange} numberPages={pages} selected={currentPage} />
        </div>
      </React.Fragment>
    );
  }
}

export default PurchaseOrderItems;
