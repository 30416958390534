import React from 'react';
import DeliveryCommentModel from '../../../../models/delivery_comment_model';
import * as swipe from '../../../shared/swipe';

class DeliveryItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowExpanded: false,
      newDeliveryComment: new DeliveryCommentModel({ body: '', user: props.currentUser }),
    };
  }

  componentDidMount = () => {
    swipe.triggerSwipeEvent();
  }

  expandRow = () => {
    this.setState(prevState => ({ rowExpanded: !prevState.rowExpanded }));
  }

  onChangeItem = ({ target: { value, name } }) => {
    const { item } = this.props;
    item[name] = value;

    this.updateItem(item);
  }

  onCommentChange = ({ target: { value } }) => {
    const { newDeliveryComment } = this.state;
    newDeliveryComment.body = value;
    this.setState({ newDeliveryComment });
  }

  onKeyPress = (e) => {
    const { state: { newDeliveryComment }, props: { item, currentUser, editableDelivery } } = this;
    if (e.key === 'Enter' && editableDelivery) {
      item.comments = [...item.comments, newDeliveryComment];
      this.setState({ newDeliveryComment: new DeliveryCommentModel({ body: '', user: currentUser }) });

      this.updateItem(item);
    }
  }

  onSendButtonClick = () => {
    const { state: { newDeliveryComment }, props: { item, currentUser, editableDelivery } } = this;

    item.comments = [...item.comments, newDeliveryComment];
    if (editableDelivery) {
      this.setState({ newDeliveryComment: new DeliveryCommentModel({ body: '', user: currentUser }) });
      this.updateItem(item);
    }
  }

  updateItem = (newItem) => {
    const { updateDeliveries, delivery } = this.props;
    const newItems = delivery.items.map((item) => {
      if (item.uuid === newItem.uuid) {
        return newItem;
      }
      return item;
    });

    delivery.items = newItems;

    updateDeliveries(delivery);
  }

  render() {
    const {
      state: {
        rowExpanded, newDeliveryComment,
      },
      props: {
        item, exchangeRate, editableDelivery,
      },
    } = this;

    return (
      <React.Fragment>
        <tr className={`fluid-not-mobile td-with-sub ${rowExpanded ? 'expanded' : ''}`}>
          <td className="not-sticky">{item.sku}</td>
          <td>{item.name}</td>
          <td className="colored-pass fluid-not-mobile">
            <input
              name="goodItemsCount"
              onChange={this.onChangeItem}
              value={item.goodItemsCount}
              type="number"
              className="input-s"
              disabled={!editableDelivery}
              placeholder="0"
            />
          </td>
          <td className="colored-fail fluid-not-mobile">
            <input
              name="badItemsCount"
              onChange={this.onChangeItem}
              value={item.badItemsCount}
              type="number"
              className="input-s"
              disabled={!editableDelivery}
              placeholder="0"
            />
          </td>
          <td className="bold">{`${item.total}/${item.quantity}`}</td>
          <td className="mute price number">{item.price}</td>
          <td className="mute">{item.unit}</td>
          <td className="mute">{item.updatedAt}</td>
          <td className="number price">
            {I18n.toNumber(item.price * item.goodItemsCount, { delimiter: ' ', precision: 2 })}
          </td>
          <td className="number price">
            {I18n.toNumber(Math.round(item.price * item.goodItemsCount * exchangeRate * 100) / 100, { delimiter: ' ', precision: 2 })}
          </td>
          <td className="actions">
            {
              item.comments.length === 0
                ? (
                  <a className="anchor mute" onClick={this.expandRow}>
                    <i className="icon-add_comment" />
                  </a>
                )
                : (
                  <a className="anchor link">
                    <i className="icon-add_comment_alt" />
                    {item.comments.length}
                  </a>
                )
            }
          </td>
          <td className="actions with-arrow">
            <a onClick={this.expandRow} className="anchor arrow" />
          </td>
        </tr>
        <tr className="td-sub fluid-not-mobile">
          <td colSpan={11}>
            <div className="td-rows">
              {
                item.comments.map(comment => (
                  <div key={comment.uuid} className="td-row">
                    <i className="icon-dialog" />
                    <div className="row-value">
                      <label className="mute">
                        {`${comment.user.firstName} ${comment.user.lastName} - ${comment.createdAt}`}
                      </label>
                      <p>
                        {comment.body}
                      </p>
                    </div>
                  </div>
                ))
              }
              {editableDelivery
                ? (
                  <div className="td-row as-input">
                    <i className="icon-dialog" />
                    <input
                      className="input-table"
                      type="text"
                      placeholder={I18n.t('purchase_orders.form.deliveries.add_comment')}
                      disabled={!editableDelivery}
                      value={newDeliveryComment.body}
                      onChange={this.onCommentChange}
                      onKeyPress={this.onKeyPress}
                      onFocus={(e) => { e.target.select(); }}
                    />
                    <button
                      className="icon-send button button-transparent no-shadow"
                      type="send"
                      onClick={this.onSendButtonClick}
                    />
                  </div>
                ) : null
              }
            </div>
          </td>
          <td />
        </tr>
        <tr className={`td-with-sub fluid-only-mobile ${rowExpanded ? 'expanded' : ''}`}>
          <td className="not-sticky">{item.sku}</td>
          <td>{item.name}</td>
          <td className="bold">{`${item.total}/${item.quantity}`}</td>
          <td className="mute price number">{item.price}</td>
          <td className="mute">{item.unit}</td>
          <td className="mute">{item.updatedAt}</td>
          <td className="number price">
            {I18n.toNumber(item.price * item.goodItemsCount, { delimiter: ' ', precision: 2 })}
          </td>
          <td className="number price">
            {I18n.toNumber(Math.round(item.price * item.goodItemsCount * exchangeRate * 100) / 100, { delimiter: ' ', precision: 2 })}
          </td>
          <td className="actions">
            {
              item.comments.length === 0
                ? (
                  <a className="anchor mute" onClick={this.expandRow}>
                    <i className="icon-add_comment" />
                  </a>
                )
                : (
                  <a className="anchor link">
                    <i className="icon-add_comment_alt" />
                    {item.comments.length}
                  </a>
                )
            }
          </td>
          <td className="colored-pass fluid-only-mobile fluid-always-display">
            <input
              name="goodItemsCount"
              onChange={this.onChangeItem}
              value={item.goodItemsCount}
              type="number"
              className="input-s"
              disabled={!editableDelivery}
              placeholder="0"
            />
          </td>
          <td className="colored-fail fluid-only-mobile fluid-always-display">
            <input
              name="badItemsCount"
              onChange={this.onChangeItem}
              value={item.badItemsCount}
              type="number"
              className="input-s"
              disabled={!editableDelivery}
              placeholder="0"
            />
          </td>
          <td className="actions with-arrow not-sticky">
            <a onClick={this.expandRow} className="anchor arrow" />
          </td>
        </tr>
        <tr className="td-sub fluid-only-mobile">
          <td colSpan={12} className="not-sticky">
            <div className="td-rows">
              {
                item.comments.map(comment => (
                  <div key={comment.uuid} className="td-row">
                    <i className="icon-dialog" />
                    <div className="row-value">
                      <label className="mute">
                        {`${comment.user.firstName} ${comment.user.lastName} - ${comment.createdAt}`}
                      </label>
                      <p>
                        {comment.body}
                      </p>
                    </div>
                  </div>
                ))
              }
              {editableDelivery
                ? (
                  <div className="td-row as-input">
                    <i className="icon-dialog" />
                    <input
                      className="input-table"
                      type="text"
                      placeholder={I18n.t('purchase_orders.form.deliveries.add_comment')}
                      disabled={!editableDelivery}
                      value={newDeliveryComment.body}
                      onChange={this.onCommentChange}
                      onKeyPress={this.onKeyPress}
                      onFocus={(e) => { e.target.select(); }}
                    />
                    <button
                      className="icon-send button button-transparent no-shadow"
                      type="send"
                      onClick={this.onSendButtonClick}
                    />
                  </div>
                ) : null
              }
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default DeliveryItem;
