import React from 'react';

const CategoryGraph = ({
  ppoEnabled, category, items, percentage, exchangeRate,
}) => {
  const incoming = items.reduce((prev, item) => prev + (item.quantity * item.price) * exchangeRate, 0);
  const plannedAmount = (ppoEnabled ? category.planned : 0.0) - incoming;
  const remaining = ppoEnabled ? category.remaining + incoming : category.remaining;

  return (
    items.length > 0
      ? (
        <React.Fragment>
          <tr className="row">
            <td className="chart-min" />
            <td className="cell-fill chart-title" colSpan="2">
              {category.displayName}
            </td>
          </tr>
          <tr className="row with-chart with-min-max">
            <td className="chart-min">0</td>
            <td className="chart-inside">
              <div className={`charts labels-hovered ${remaining - incoming < 0 ? 'warning' : null}`}>
                <div className="chart has-label invoiced" style={{ width: `${percentage(category.invoiced, category.amount)}%` }}>
                  <span />
                  <div className="chart-label">
                    <div>
                      <div className="chart-label-name">{I18n.t('purchase_orders.form.budget_graph.invoiced')}</div>
                      <div className="chart-label-value">{I18n.toNumber(category.invoiced, { delimiter: ' ', precision: 0 })}</div>
                    </div>
                    <span />
                  </div>
                </div>
                <div className="chart has-label not-invoiced" style={{ width: `${percentage(category.notInvoiced, category.amount)}%` }}>
                  <span />
                  <div className="chart-label">
                    <div>
                      <div className="chart-label-name">{I18n.t('purchase_orders.form.budget_graph.open_commitments')}</div>
                      <div className="chart-label-value">{I18n.toNumber(category.notInvoiced, { delimiter: ' ', precision: 0 })}</div>
                    </div>
                    <span />
                  </div>
                </div>
                { ppoEnabled ? (
                  <div className="chart has-label planned" style={{ width: `${percentage(plannedAmount, category.amount)}%` }}>
                    <span />
                    <div className="chart-label">
                      <div>
                        <div className="chart-label-name">{I18n.t('purchase_orders.form.budget_graph.planned')}</div>
                        <div className="chart-label-value">{I18n.toNumber(plannedAmount, { delimiter: ' ', precision: 0 })}</div>
                      </div>
                      <span />
                    </div>
                  </div>
                ) : null
                }
                <div className="chart has-label current" style={{ width: `${percentage(incoming, category.amount)}%` }}>
                  <span />
                  <div className="chart-label">
                    <div>
                      <div className="chart-label-name">{I18n.t('purchase_orders.form.budget_graph.this_purchase')}</div>
                      <div className="chart-label-value">{I18n.toNumber(incoming, { delimiter: ' ', precision: 0 })}</div>
                    </div>
                    <span />
                  </div>
                </div>
                <div className="chart has-label remaining" style={{ width: `${percentage(remaining - incoming, category.amount)}%` }}>
                  <span />
                  <div className="chart-label">
                    <div>
                      <div className="chart-label-name">{I18n.t('purchase_orders.form.budget_graph.remaining')}</div>
                      <div className="chart-label-value">{I18n.toNumber(remaining - incoming, { delimiter: ' ', precision: 0 })}</div>
                    </div>
                    <span />
                  </div>
                </div>
              </div>
            </td>
            <td className="chart-max">{I18n.toNumber(category.amount, { delimiter: ' ', precision: 0 })}</td>
          </tr>
        </React.Fragment>
      ) : null
  );
};

export default CategoryGraph;
