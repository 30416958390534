import React from 'react';
import { humanizedPrice } from '../shared/number_converter';

const GlobalProductRow = ({
  supplierItem, onChangeBulkAction, onEditClick, onShowClick, onDeleteClick,
}) => (
  <div className="tr" style={{ cursor: 'pointer' }}>
    <div className="td has-child">
      <label className="has-checkbox as-td">
        <input
          className="count-selected"
          type="checkbox"
          checked={supplierItem.bulkActionsSelected}
          onChange={() => onChangeBulkAction(supplierItem.id)}
        />
        <span className="checkbox input-s" />
      </label>
      <div className="as-td has-ellipsis" data-ellipsis={supplierItem.productNumber}>
        <div className="text-ellipsis w-7">
          <span onClick={() => onShowClick(supplierItem)}>
            {supplierItem.productNumber}
          </span>
        </div>
      </div>
    </div>
    <div onClick={() => onShowClick(supplierItem)} className="td has-ellipsis" data-ellipsis={supplierItem.description}>
      <div className="text-ellipsis w-10">{supplierItem.description}</div>
    </div>
    <div onClick={() => onShowClick(supplierItem)} className="td has-ellipsis" data-ellipsis={supplierItem.unitMeasure}>
      <div className="text-ellipsis w-3">{supplierItem.unitMeasure}</div>
    </div>
    <div onClick={() => onShowClick(supplierItem)} className="td number">{supplierItem.minimumOrderQuantity}</div>
    <div onClick={() => onShowClick(supplierItem)} className="td number bold">
      {humanizedPrice(supplierItem.grossPrice)}
    </div>
    <div onClick={() => onShowClick(supplierItem)} className="td number">
      {`${humanizedPrice(supplierItem.discount)} %`}
    </div>
    <div onClick={() => onShowClick(supplierItem)} className="td number bold">
      {humanizedPrice(supplierItem.netPrice)}
    </div>
    <div onClick={() => onShowClick(supplierItem)} className="td">{supplierItem.currency}</div>
    <div className="td actions sticky-right hoverable">
      <a onClick={() => onEditClick(supplierItem)} className="edit">
        <i className="icon-edit" />
      </a>
      <a onClick={() => onDeleteClick(supplierItem.id)} className="delete">
        <i className="icon-delete" />
      </a>
    </div>
  </div>
);

export default GlobalProductRow;
