import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import ArchiveService from '../../services/archive_service';
import PurchaseOrderFilters from '../accountings/shared/purchase_order_filters';
import PurchaseOrderRow from './purchase_orders/purchase_order_row';
import ColumnViewSettings from './common/column_view_settings';
import * as swipe from '../shared/swipe';
import Table from '../shared/table';
import TableHead from '../accountings/shared/table_head';
import PaginateWithDots from '../item_list/paginate_with_dots';
import Loader from '../loader';
import ExportButton from '../shared/buttons/export_button';

class PurchaseOrders extends Table {
  constructor(props) {
    super(props);

    const urlFilters = this.urlParamsSetup();
    const page = urlFilters.page ? parseInt(urlFilters.page) : 1;

    this.order = urlFilters.order;

    this.state = {
      purchaseOrders: [],
      pages: 1,
      currentPage: page,
      projectSettings: [],
      accountSettings: [],
      columnView: [],
      customFieldSettings: [],
      filters: [],
      selectedFilters: urlFilters.filters || {},
      filterActions: [],
      filtersComponentKey: 1,
      columnViewAttributes: [],
    };
  }

  componentDidMount() {
    const { currentCompany } = this.props;

    swipe.triggerSwipeEvent();
    this.onTableLoading();
    this.controller = new AbortController();
    ArchiveService.purchaseOrders(window.location.search, this.controller.signal).then(({
      purchaseOrders, columnView, customFieldSettings, pages, filters, fieldSettings,
    }) => {
      const projectSettings = fieldSettings.filter(setting => setting.field_name === 'project')[0];
      const accountSettings = fieldSettings.filter(setting => setting.field_name === 'account')[0];

      this.setState({
        purchaseOrders,
        columnView,
        customFieldSettings,
        projectSettings,
        accountSettings,
        pages,
        filters,
        filterActions: filters ? Object.keys(filters) : [],
        columnViewAttributes: columnView.purchaseOrderColumns(currentCompany, projectSettings, customFieldSettings, accountSettings),
      });
      this.onTableLoad();
      window.initTableFluidManual();
    }).catch((err) => {
      if (!axios.isCancel(err)) throw err;
    });
  }

  componentDidUpdate() {
    window.resetTableFluidManual();
  }

  onFilter = ({ name, value }) => {
    const stringValue = value.toString();
    const { selectedFilters } = this.state;

    const newFilters = { ...selectedFilters, [name]: [...new Set([selectedFilters[name], stringValue].flatten().filter(Boolean))] };
    this.setState({ selectedFilters: newFilters });
    this.filter({}, 1, newFilters);
  }

  filter = (additionalState = {}, page = 1, filters = this.state.selectedFilters) => {
    const attributes = {
      custom: Object.keys(filters).map(key => ({ name: key, value: filters[key] })),
      page,
      order: this.order,
    };

    this.onTableLoading();
    this.controller.abort();
    this.controller = new AbortController();
    ArchiveService.purchaseOrdersFilter(attributes, this.controller.signal).then(({ purchaseOrders, pages, query }) => {
      window.history.pushState({}, 'Archive', query);
      this.setState({
        purchaseOrders,
        pages,
        currentPage: 1,
        ...additionalState,
      });
      this.onTableLoad();
    }).catch((err) => {
      if (!axios.isCancel(err)) throw err;
    });
  }

  filterDeleted = () => {
    const { selectedFilters } = this.state;
    const newFilters = { ...selectedFilters, state: selectedFilters.state?.includes('deleted') ? [] : ['deleted'] };

    this.setState({ selectedFilters: newFilters });

    this.filter({}, 1, newFilters);
  }

  updateColumnView = ({ target: { value } }) => {
    const { columnView, customFieldSettings } = this.state;
    columnView[value] = !columnView[value];
    this.setState({
      columnView,
    }, () => {
      ArchiveService.updateColumnView({
        type: 'purchase_order',
        column_view: columnView.toParams('purchase_order', customFieldSettings),
      });
    });
  }

  headers = () => {
    const {
      state: {
        columnView,
        projectSettings,
        accountSettings,
        customFieldSettings,
        purchaseOrders,
      },
      props: { currentCompany },
    } = this;

    if (!columnView) return [];

    return [
      {
        name: 'po_number',
        class: 'sticky-left',
        translation: 'purchase_orders.po_number',
        visible: columnView.poNumber,
      },
      {
        name: 'parent_po_number',
        translation: 'purchase_orders.parent_po',
        visible: columnView.parentPo && currentCompany.parentPoEnabled,
      },
      {
        name: 'date_raised',
        class: 'sticky-left',
        translation: 'purchase_orders.date_raised',
        visible: columnView.dateRaised,
      },
      {
        name: 'delivery_date',
        class: 'sticky-left',
        translation: 'purchase_orders.delivery_date',
        visible: columnView.deliveryDate,
      },
      {
        name: 'expected_delivery_date',
        class: 'sticky-left',
        translation: 'purchase_orders.expected_delivery_date',
        visible: columnView.expectedDeliveryDate,
      },
      {
        name: 'confirmed_delivery_date',
        class: 'sticky-left',
        translation: 'purchase_orders.confirmed_delivery_date',
        visible: columnView.confirmedDeliveryDate,
      },
      {
        name: 'supplier',
        class: 'sticky-left',
        translation: 'purchase_orders.supplier',
        visible: columnView.supplier,
      },
      {
        name: 'budget',
        class: 'sticky-left',
        translation: 'purchase_orders.budget',
        visible: columnView.budget,
      },
      {
        name: 'description',
        class: 'sticky-left',
        translation: 'purchase_orders.description',
        visible: columnView.description,
      },
      {
        name: 'department',
        class: 'sticky-left',
        translation: 'purchase_orders.department',
        visible: columnView.department,
      },
      {
        name: 'project_name',
        class: 'sticky-left',
        translation: 'purchase_orders.project_name',
        visible: columnView.project && projectSettings && projectSettings.active,
      },
      {
        name: 'po_value',
        class: 'number price',
        translation: 'purchase_orders.po_value',
        visible: columnView.poValue,
      },
      {
        name: 'currency',
        class: 'number price',
        translation: 'purchase_orders.currency',
        visible: columnView.currency,
      },
      {
        name: 'po_value_in_currency',
        class: 'number price',
        translation: 'purchase_orders.po_value_in_currency',
        attributes: { currency: currentCompany.currency },
        visible: columnView.poValueInCurrency,
      },
      {
        name: 'not_invoiced',
        class: 'number price',
        translation: 'purchase_orders.not_invoiced',
        visible: columnView.notInvoiced,
      },
      {
        name: 'invoiced',
        class: 'number price',
        translation: 'purchase_orders.invoiced',
        visible: columnView.invoiced,
      },
      {
        name: 'committed',
        class: 'number price',
        translation: 'purchase_orders.committed',
        visible: columnView.committed,
      },
      {
        name: 'status',
        class: 'sticky-left has-status',
        translation: 'purchase_orders.status',
        visible: columnView.status,
      },
      {
        name: 'created_by',
        class: 'sticky-left text-left',
        translation: 'purchase_orders.created_by',
        visible: columnView.createdBy,
      },
      {
        name: 'approved_by',
        class: 'sticky-left text-left',
        translation: 'purchase_orders.approved_by',
        visible: columnView.approvedBy,
      },
      {
        name: 'customer',
        translation: 'purchase_orders.customer',
        visible: columnView.customer && currentCompany.customPoEnabled,
      },
      {
        name: 'sales_order',
        translation: 'purchase_orders.sales_order',
        visible: columnView.salesOrder && currentCompany.customPoEnabled,
      },
      {
        name: 'purchase_type',
        translation: 'purchase_orders.purchase_type',
        visible: columnView.purchaseType && currentCompany.customPoEnabled,
      },
      {
        name: 'account',
        translation: 'purchase_orders.gl_code',
        visible: columnView.account && accountSettings && accountSettings.active,
      },
      customFieldSettings.map(field => (
        {
          name: field.slug,
          displayName: field.name,
          visible: columnView[_.camelCase(field.slug)],
        }
      )),
      {
        name: 'comments',
        translation: 'purchase_orders.comments',
        visible: columnView.comments,
      },
      {
        name: 'info',
        translation: 'purchase_orders.info',
        visible: purchaseOrders?.filter(({ outdated }) => outdated)?.length > 0,
      },
    ].flat(1).filter(object => object.visible);
  }

  exportUrl = (format, type) => {
    const url = new URL(`${window.location.origin}/api/archive/purchase_orders.${format}${window.location.search}`);
    if (type) { url.searchParams.append('type', type); }

    return url;
  }

  render() {
    const {
      state: {
        purchaseOrders, isLoading, filters, filterActions, selectedFilters,
        pages, currentPage, columnView, projectSettings, accountSettings,
        columnViewAttributes, customFieldSettings,
      },
      props: { currentCompany },
    } = this;
    const dotsNumber = this.headers().length;
    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <React.Fragment>
        <div className="tab-addons">
          <ColumnViewSettings
            columnView={columnView}
            handleUpdate={this.updateColumnView}
            visibleColumns={columnViewAttributes}
            customFieldSettings={customFieldSettings}
          />
          <ExportButton exportUrl={this.exportUrl} usExportFormat={currentCompany.usExportFormat} inlineDownload={(pages * purchaseOrders.length) < 15000} />
        </div>
        <PurchaseOrderFilters
          onFilter={this.onFilter}
          onRemoveFilter={this.onRemoveFilter}
          onDateFilter={this.onDateFilter}
          onQueryFilter={this.onQueryFilter}
          currentFilters={selectedFilters}
          clearDate={this.clearDate}
          filters={filters}
          filterActions={filterActions}
          integrationRefesh={false}
          exportEnable={false}
        />
        <div>
          <div className={`table-fluid table-scrollable table-column-${dotsNumber}${htmlClasses}`}>
            <Loader />
            {this._renderInputs(dotsNumber)}
            <div className="table-header with-filters">
              <div className="table-nav">
                {this._renderLabels(dotsNumber)}
                {this._renderDots(dotsNumber)}
              </div>
            </div>
            <div className="table">
              <div className="colgroup">
                {this._renderColls(dotsNumber)}
              </div>
              <TableHead
                onOrder={this.onOrder}
                order={this.order}
                headers={this.headers()}
              />
              <div className="tbody">
                {!isLoading && purchaseOrders.map(purchaseOrder => (
                  <PurchaseOrderRow
                    key={purchaseOrder.id}
                    purchaseOrder={purchaseOrder}
                    columnView={columnView}
                    currentCompany={currentCompany}
                    projectSettings={projectSettings}
                    accountSettings={accountSettings}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="tile-footer">
          {selectedFilters.state?.includes('deleted')
            ? <a className="link" onClick={this.filterDeleted}>{I18n.t('archive.hide_deleted_po')}</a>
            : <a className="link" onClick={this.filterDeleted}>{I18n.t('archive.show_deleted_po')}</a>
          }
          <PaginateWithDots onPageChange={this.onPageChange} numberPages={pages} selected={currentPage} />
        </div>
      </React.Fragment>
    );
  }
}

export default PurchaseOrders;
